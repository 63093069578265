export default {
  en: [
    {
      q: 'In the Dreamland video, the Red Bull athletes …',
      a: ['do parkour', 'are running'],
    },
    {
      q: 'In the Dreamland video, there were ... Red Bull athletes.',
      a: ['2', '3'],
    },
    {
      q:
        'According to Futbolist, the ideal number of fingers for close-range plays in PubG Mobile is …',
      a: ['4', '3'],
    },
    {
      q: "PubG Mobile's first map is …",
      a: ['Erangel', 'Miramar'],
    },
    {
      q:
        'The Basilica Cistern, which was made part of some extraordinary wakeboarding action by Red Bull athletes, is … years old.',
      a: ['1500', '950'],
    },
    {
      q: 'Hearthstone will turn … this year.',
      a: ['7', '12'],
    },
    {
      q: 'There are … classes in Hearthstone.',
      a: ['10', '8'],
    },
    {
      q: 'The Red Bull SoloQ Finals were held in …',
      a: ['Esma Sultan', 'Hasköy Wool Yarn Factory'],
    },
    {
      q:
        'How many players does it take to play a game of TFT, released in 2019?',
      a: ['8', '6'],
    },
    {
      q:
        'The Red Bull Air Race action took place in the ... district of Istanbul.',
      a: ['Haliç', 'Caddebostan'],
    },
    {
      q: 'Red Bull M.E.O. Season 3 PubG Mobile Finals took place on a ...',
      a: ['Ferry', 'Plane'],
    },
    {
      q:
        'The final showdown in Red Bull M.E.O. Season 3 PubG Mobile Finals, which took place in the middle of the Bosphorus, came to an end with … as the champions.',
      a: ['Futbolist', 'BLAZE ESPORT'],
    },
    {
      q:
        'Red Bull M.E.O. Season 3 PubG Mobile Finals, which took place in the middle of the Bosphorus, came to an end with the … side as the champions.',
      a: ['European', 'Asian'],
    },
    {
      q: 'Red Bull Racing pilot Alex Albon took ... to cross the Bosphorus.',
      a: ['The Eurasia Tunnel', 'The Fatih Sultan Mehmet Bridge'],
    },
    {
      q:
        'With the race in Turkey, Red Bull Racing has now participated in a total of … F1 races.',
      a: ['300', '274'],
    },
    {
      q: 'Who won the Ultimate Race?',
      a: [
        'Kenan Sofuoğlu - Motorbike',
        'Erhan Günari and Serdar Doğan - Solotürk F-16',
      ],
    },
    {
      q:
        'Mad Mike, Abdo Feghali and Aleksandr Grinchuk drifted with the iconic … in the Red Bull Car Park Drift World Final.',
      a: ['Murat 124', 'Toros'],
    },
    {
      q: '4. Red Bull Flugtag took place at the …',
      a: ['Caddebostan Coast', 'Bakırköy Coast'],
    },
    {
      q:
        'Who piloted the Red Bull Air Race Plane as it took flight over Caddebostan?',
      a: ['Dario Costa', 'Łukasz Czepiela'],
    },
    {
      q: 'How many teams got wings the on 4. Red Bull Flugtag?',
      a: ['35', '23'],
    },
    {
      q: 'The audience was … strong on the 4. Red Bull Flugtag.',
      a: ['200.000', '345.000'],
    },
    {
      q:
        'The Chicken Whisperers, consisting of 5 engineers, broke the world record with … meters on Red Bull Flight Day.',
      a: ['78,5', '47,5'],
    },
    {
      q: 'Ayşe Begüm Onbaşı broke out of the …',
      a: ['Topkapı Palace', 'Dolmabahçe Palace'],
    },
    {
      q:
        "Alex Mason's extraordinary and skillful show of slacklining took place in ...",
      a: ['Balat', "The Princes' Islands"],
    },
  ],
  tr: [
    {
      q: 'DREAMLAND VİDEOSUNDA RED BULL ATLETLERİ HANGİ SPORU YAPIYORLAR?',
      a: ['Parkur', 'Koşu'],
    },
    {
      q: 'DREAMLAND VİDEOSUNDA KAÇ RED BULL ATLETİ VARDI?',
      a: ['2', '3'],
    },
    {
      q:
        "FUTBOLİST'E GÖRE PUBG MOBILE'DA YAKIN MESAFE OYUNLARDA KAÇ PARMAKLA OYNAMAK İDEAL?",
      a: ['4', '3'],
    },
    {
      q: "PUBG MOBILE'IN İLK HARİTASI HANGİSİDİR?",
      a: ['ERANGEL', 'MIRAMAR'],
    },
    {
      q:
        'RED BULL SPORCULARI TARAFINDAN SIRA DIŞI BİR WAKEBOARD AKSİYONUNUN PARÇASI HALNE GETİRİLEN YEREBATAN SARNICI KAÇ YAŞINDADIR?',
      a: ['1500', '950'],
    },
    {
      q: 'HEARTHSTONE BU SENE KAÇINCI YAŞINI KUTLAYACAK?',
      a: ['7', '12'],
    },
    {
      q: 'HEARTHSTONE İÇERİSİNDE KAÇ ADET SINIF VARDIR?',
      a: ['10', '8'],
    },
    {
      q: 'RED BULL SOLO Q FİNALLERİ NEREDE YAPILMIŞTIR?',
      a: ['Esma Sultan', 'Hasköy Yün İplik Fabrikası'],
    },
    {
      q: '2019 YILINDA PİYASAUA ÇIKAN TFT KAÇ OYUNCU İLE OYNANIR?',
      a: ['8', '6'],
    },
    {
      q: "RED BULL AIR RACE HEYECANI İSTANBUL'DA NEREDE YAŞANMIŞTIR?",
      a: ['HALİÇ', 'Caddebostan'],
    },
    {
      q: 'RED BULL M.E.O. SEZON 3 PUBG MOBILE FİNALLERİ NEREDE OYNANMIŞTIR?',
      a: ['FERİBOT', 'UÇAK'],
    },
    {
      q:
        "RED BULL M.E.O. SEZON 3 PUBG MOBILE FİNALLERİNDE BOĞAZ'IN ORTASINDAKİ MEYDAN OKUMADA ŞAMPİYON KİM OLMUŞTUR?",
      a: ['FUTBOLİST', 'BLAZE ESPORT'],
    },
    {
      q:
        "RED BULL M.E.O. SEZON 3 PUBG MOBILE FİNALLERİNDE BOĞAZ'IN ORTASINDAKİ MEYDAN OKUMADA ŞAMPİYON HANGİ YAKA OLMUŞTUR?",
      a: ['Avrupa', 'Asya'],
    },
    {
      q:
        'RED BULL RACING PİLOTU ALEX ALBON BOĞAZI GEÇMEK İÇİN HANGİ YOLU KULLANMIŞTIR?',
      a: ['AVRASYA TÜNELİ', 'FATİH SULTAN MEHMET KÖPRÜSÜ'],
    },
    {
      q:
        "RED BULL RACING TAKIMININ TÜRKİYE'DEKİ YARIŞ F1 TAKIMLARININ KAÇINCI YARIŞIDIR?",
      a: ['300', '274'],
    },
    {
      q: "ULTIMATE RACE'İN KAZANANI KİM OLMUŞTUR?",
      a: [
        'KENAN SOFUOĞLU - MOTORSİKLET',
        'ERHAN GÜNARİ VE SERDAR DOĞAN - SOLOTÜRK F-16 İLE',
      ],
    },
    {
      q:
        "MAD MIKE, ABDO FEGHALİ VE ALEKSANDR GRINCHUK'UN RED BULL CAR PARK DRIFT DÜNYA FİNALİ'NDE DRIFT YAPMAK İÇİN KULLANDIĞI İKONİK ARABA HANGİSİDİR?",
      a: ['Murat 124', 'Toros'],
    },
    {
      q: '4. RED BULL UÇUŞ GÜNÜ NEREDE GERÇEKLEŞTİRİLMİŞTİR?',
      a: ['CADDEBOSTAN SAHİLİ', 'BAKIRKÖY SAHİLİ'],
    },
    {
      q:
        "CADDEBOSTAN SEMALARINDA SÜZÜLEN RED BULL AIR RACE UÇAĞI'NIN PİLOTU KİMDİR?",
      a: ['DARIO COSTA', 'ŁUKASZ CZEPIELA'],
    },
    {
      q: "4. RED BULL UÇUŞ GÜNÜ'NDE KAÇ TAKIM KANATLANMIŞTIR?",
      a: ['35', '23'],
    },
    {
      q: "4. RED BULL UÇUŞ GÜNÜ'NÜ KAÇ KİŞİ YERİNDE İZLEMİŞTİR?",
      a: ['200.000', '345.000'],
    },
    {
      q:
        "RED BULL UÇUŞ GÜNÜ'NDE BEŞ MÜHENDİSTEN OLUŞAN THE CHICKEN WHISPERERS TAKIMI KAÇ METRE İLE DÜNYA REKORU KIRMIŞTIR?",
      a: ['78,5', '47,5'],
    },
    {
      q: 'AYŞE BEGÜM ONBAŞI KAPALI KALDIĞI HANGİ SARAYDAN KAÇMIŞTIR?',
      a: ['Topkapı Sarayı', 'Dolmabahçe Sarayı'],
    },
    {
      q:
        "İP ÜSTÜNDE HARİKALAR YARATAN ALEX MASON'IN SIRA DIŞI GÖSTERİSİNE NERESİ EV SAHİPLİĞİ YAPMIŞTIR?",
      a: ['Balat', 'Adalar'],
    },
  ],
};
