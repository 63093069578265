import FingerprintJS from '@fingerprintjs/fingerprintjs';
import store from '../store';

let fp, instance;

export default async function () {
  if (fp) {
    return { fp, instance };
  }

  instance = await FingerprintJS.load();
  fp = await instance.get();
  store.commit('SET_VISITORID', fp.visitorId);
  return { fp, instance };
}
