<template>
  <div ref="elem">
    <Hotspot :design="mergedDesign" @click="handleClick"> </Hotspot>
    <portal to="renderer">
      <Modal v-model="active">
        <div
          v-if="active"
          class="w-11/12 sm:w-8/12 max-w-xl flex justify-center items-center relative"
        >
          <div
            class="relative w-full aspect-w-1 aspect-h-1 bg-contain bg-no-repeat bg-center"
            :style="`background-image: url(${require('../../assets/pubg/pubg.jpg')})`"
          >
            <div
              class="absolute top-0 left-0 right-0 bottom-0 p-6 sm:p-8 md:p-10 lg:14"
            >
              <div
                v-if="showInfoScreens"
                class="w-full h-full flex flex-col justify-center items-center space-y-5"
              >
                <div
                  class="max-w-sm text-lg md:text-4xl text-center textDropShadow"
                  v-if="infoStep === 0"
                >
                  {{ $t('drop.intro') }}
                </div>
                <div v-if="infoStep === 0">
                  <button
                    class="bg-white rounded-xl text-gray-800 px-3 md:px-8 py-1 md:py-2 text-sm md:text-xl xl:text-2xl 2xl:text-3xl font-black shadow-miniglow focus:outline-none focus:ring-0"
                    @click="infoStep = 1"
                  >
                    {{ $t('drop.next') }}
                    <i class="mdi mdi-arrow-right-thick mr-2" />
                  </button>
                </div>
                <div
                  v-if="infoStep === 1"
                  class="max-w-sm text-xs md:text-xl font-subhead font-bold text-center textDropShadow uppercase"
                >
                  {{ $t('drop.info') }}
                </div>
                <div v-if="infoStep === 1">
                  <button
                    class="bg-white rounded-xl text-gray-800 px-3 md:px-8 py-1 md:py-2 text-sm md:text-xl xl:text-2xl 2xl:text-3xl font-black shadow-miniglow focus:outline-none focus:ring-0"
                    @click="showInfoScreens = false"
                  >
                    {{ $t('drop.next')
                    }}<i class="mdi mdi-arrow-right-thick mr-2" />
                  </button>
                </div>
              </div>
              <div
                v-else-if="firstVisit && dropId"
                class="w-full h-full flex flex-col justify-center items-center space-y-5"
              >
                <div class="hidden block:md">
                  <img
                    class="w-32 h-auto animate-pulse transition-opacity"
                    :src="require(`../../assets/pubg/${dropId}.png`)"
                  />
                </div>
                <div
                  class="max-w-sm text-sm md:text-4xl text-center textDropShadow"
                  v-html="$t('drop.section_finish')"
                ></div>
                <div
                  class="max-w-sm text-xs md:text-xl font-subhead font-bold text-center textDropShadow uppercase"
                >
                  {{ $t('drop.trophy_room') }}
                </div>
                <div>
                  <button
                    class="bg-white rounded-xl text-gray-800 px-3 md:px-8 py-1 md:py-2 text-sm md:text-xl xl:text-2xl 2xl:text-3xl font-black shadow-miniglow focus:outline-none focus:ring-0"
                    @click="firstVisit = false"
                  >
                    {{ $t('drop.next') }}
                    <i class="mdi mdi-arrow-right-thick mr-2" />
                  </button>
                </div>
              </div>
              <div
                v-else-if="
                  !isCompleted && !dropId && !firstVisit && infoStep === 0
                "
                class="w-full h-full flex flex-col justify-center items-center space-y-5"
              >
                <div
                  class="max-w-sm text-4xl text-center textDropShadow"
                  v-html="$t('drop.sorry')"
                ></div>
                <div
                  class="max-w-sm text-xl font-subhead font-bold text-center textDropShadow uppercase"
                >
                  {{ $t('drop.go_back') }}
                </div>
                <div>
                  <button
                    class="bg-white rounded-xl text-gray-800 px-3 md:px-8 py-1 md:py-2 text-sm md:text-xl xl:text-2xl 2xl:text-3xl font-black shadow-miniglow focus:outline-none focus:ring-0"
                    @click="infoStep = 1"
                  >
                    <i class="mdi mdi-arrow-right-thick mr-2" />
                  </button>
                </div>
              </div>
              <div
                v-else-if="isCompleted && !dropId"
                class="w-full h-full flex flex-col justify-center items-center space-y-5"
              >
                <div
                  class="max-w-sm text-4xl text-center textDropShadow"
                  v-html="$t('drop.congrats')"
                ></div>
                <div
                  v-if="$root.version === 'tr'"
                  class="max-w-sm text-2xl font-subhead font-bold text-center textDropShadow uppercase"
                >
                  {{ $t('drop.share_info') }}
                </div>
                <div v-if="$root.version === 'tr'">
                  <button
                    class="bg-white rounded-xl text-gray-800 px-3 md:px-8 py-1 md:py-2 text-sm md:text-xl xl:text-2xl 2xl:text-3xl font-black shadow-miniglow focus:outline-none focus:ring-0"
                    @click="handleFormClick"
                  >
                    {{ $t('drop.fill_form') }}
                  </button>
                </div>
              </div>
              <div v-else class="w-full h-full grid grid-cols-3">
                <div
                  class="relative flex justify-center items-center p-2"
                  v-for="i in drops"
                  :key="i"
                >
                  <img
                    :class="
                      isVisited('drop:' + i)
                        ? dropId === i
                          ? 'animate-pulse transition-opacity'
                          : ''
                        : 'opacity-70'
                    "
                    :src="require(`../../assets/pubg/${i}.png`)"
                  />
                  <div
                    v-if="!isVisited('drop:' + i)"
                    class="absolute top-0 left-0 right-0 bottom-0 p-5"
                  >
                    <img :src="require(`../../assets/pubg/lock.png`)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal v-model="form">
        <div
          v-if="form"
          class="w-11/12 sm:w-10/12 max-w-2xl h-screen py-10 flex justify-center items-center relative"
        >
          <iframe
            class="w-full h-full border-none"
            frameborder="0"
            src="https://redbull.jotform.com/form/210663657984064"
          ></iframe>
        </div>
      </Modal>
    </portal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { merge, intersection, isEqual } from 'lodash';
import md5 from 'md5';
import Hotspot from './Hotspot';
import Modal from '../modal/Modal';
// import Button from '@/components/Button';

export default {
  name: 'DropKupaHotspot',
  props: {
    sceneData: { type: Object },
    view: { type: Object },
    scene: { type: Object },
    design: { type: Object, default: () => ({}) },
    coords: { type: Object },
    //
    dropId: { type: String, default: '' },
  },
  components: { Hotspot, Modal },
  head() {
    return {
      link: [
        {
          rel: 'preload',
          as: 'image',
          href: require('../../assets/pubg/lock.png'),
        },
        {
          rel: 'preload',
          as: 'image',
          href: require('../../assets/pubg/pubg.png'),
        },
        ...this.drops.map((i) => {
          return {
            rel: 'preload',
            as: 'image',
            href: require(`../../assets/pubg/${i}.png`),
          };
        }),
      ],
    };
  },
  mounted() {
    this.scene.hotspotContainer().createHotspot(this.$refs.elem, this.coords, {
      perspective: {
        radius: this.dropId ? 1400 : 700,
      },
    });
  },
  data() {
    return {
      active: false,
      showInfoScreens: false,
      infoStep: 0,
      firstVisit: false,
      form: false,
      drops: [
        'balat',
        'caddebostan',
        'galata',
        'haydarpasa',
        'kapalicarsi',
        'kopru',
        'ortakoy',
        'sultanahmet',
        'vapur',
      ],
    };
  },
  computed: {
    mergedDesign() {
      if (!this.dropId) {
        return merge({ icon: 'mdi-trophy' }, this.design);
      }
      return merge({ icon: 'mdi-parachute' }, this.design);
      /*
      return merge(
        { reset: true, class: 'w-16 h-16 border-2 border-brand-red' },
        this.design
      );
      */
    },
    dropTargets() {
      return this.drops.map((i) => {
        return md5('drop:' + i);
      });
    },
    isStarted() {
      return (
        intersection([...this.dropTargets, md5('drop:')], this.progress)
          .length > 0
      );
    },
    isCompleted() {
      return isEqual(
        this.dropTargets,
        intersection(this.dropTargets, this.progress)
      );
    },
    ...mapGetters(['isVisited']),
    ...mapState({
      progress: (s) => s.progress,
    }),
  },
  methods: {
    handleClick() {
      if (!this.isStarted) {
        this.showInfoScreens = true;
      }
      if (!this.isVisited('drop:' + this.dropId)) {
        this.firstVisit = true;
      }
      this.$store.dispatch('visit', { type: 'drop', id: this.dropId });
      this.active = true;
    },
    handleFormClick() {
      this.active = false;
      this.form = true;
    },
  },
};
</script>
