<template>
  <div ref="elem">
    <Hotspot :design="mergedDesign" @click="handleClick"> </Hotspot>
    <portal to="renderer">
      <Modal v-model="active">
        <div
          class="w-10/12 h-screen max-w-2xl relative py-10 flex justify-center items-center"
        >
          <div
            v-if="!design.hideTitleBar"
            class="absolute top-10 left-0 right-0 h-10 sm:h-16 bg-gray-800 flex flex-row justify-between items-center px-3 sm:px-6 text-white"
          >
            <div class="font-semibold sm:text-xl">{{ title }}</div>
          </div>
          <iframe
            v-if="active"
            class="w-full h-full border-none"
            frameborder="0"
            :src="src"
          ></iframe>
        </div>
      </Modal>
    </portal>
  </div>
</template>

<script>
import { merge } from 'lodash';
import Hotspot from './Hotspot';
import Modal from '../modal/Modal';

export default {
  name: 'IframeHotspot',
  props: {
    sceneData: { type: Object },
    view: { type: Object },
    scene: { type: Object },
    design: { type: Object, default: () => ({}) },
    coords: { type: Object },
    //
    src: { type: String },
    title: { type: String, default: '' },
  },
  components: { Hotspot, Modal },
  mounted() {
    this.scene.hotspotContainer().createHotspot(this.$refs.elem, this.coords, {
      perspective: {
        radius: 700,
      },
    });
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    mergedDesign() {
      return merge({ icon: 'mdi-book-open-variant' }, this.design);
    },
  },
  methods: {
    handleClick() {
      this.$store.dispatch('visit', { type: 'iframe', id: this.src });
      this.active = true;
    },
  },
};
</script>
