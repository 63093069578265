<template>
  <div ref="elem">
    <Hotspot :design="mergedDesign" @click="handleClick"> </Hotspot>
  </div>
</template>

<script>
import { merge } from 'lodash';
import Hotspot from './Hotspot';
export default {
  name: 'SceneHotspot',
  props: {
    sceneData: { type: Object },
    view: { type: Object },
    scene: { type: Object },
    design: { type: Object, default: () => ({}) },
    coords: { type: Object },
    //
    url: { type: String },
  },
  components: { Hotspot },
  mounted() {
    this.scene.hotspotContainer().createHotspot(this.$refs.elem, this.coords, {
      perspective: {
        radius: 700,
      },
    });
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    mergedDesign() {
      return merge({ icon: 'mdi-link-variant' }, this.design);
    },
  },
  methods: {
    calculateDegree(degree) {
      return (degree / 180 || 0) * Math.PI;
    },
    handleClick() {
      this.$store.dispatch('visit', { type: 'link', id: this.url });
      window.open(this.url, '_blank');
      // console.log(this.scene.view.yaw(), this.scene.view.pitch());
    },
  },
};
</script>
