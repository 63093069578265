<template>
  <div
    :class="hotspotClasses"
    @click="$emit('click', $event)"
    :style="hotspotStyle"
  >
    <Popper :disabled="!design.tooltip" :options="{ placement: 'right' }">
      <div class="popper w-64">
        <div
          class="text-sm bg-gray-800 bg-opacity-80 rounded px-3 py-1 text-white"
        >
          {{ design.tooltip }}
        </div>
      </div>
      <div slot="reference">
        <slot />
      </div>
    </Popper>
  </div>
</template>

<script>
import Popper from 'vue-popperjs';

export default {
  name: 'Hotspot',
  props: {
    design: { type: Object, default: () => ({}) },
  },
  components: { Popper },
  data() {
    return {};
  },
  computed: {
    hotspotClasses() {
      return [this.design.class];
    },
    hotspotStyle() {
      return Object.assign({}, this.design?.style);
    },
  },
};
</script>
