<template>
  <div ref="elem">
    <Hotspot :design="mergedDesign" @click="handleClick"> </Hotspot>
    <portal to="renderer">
      <div v-if="active" v-scroll-lock="false"></div>
      <Modal v-model="active">
        <div class="w-11/12 sm:w-1/2 md:w-8/12 lg:w-5/12 max-w-2xl relative">
          <div
            class="riddle_target w-full max-w-2xl max-h-screen overflow-y-auto"
            :data-rid-id="riddleId"
            data-auto-scroll="true"
          >
            <iframe
              title="riddle-embed"
              class="w-full h-96 border-none"
              :src="riddleUrl"
            ></iframe>
          </div>
        </div>
      </Modal>
    </portal>
  </div>
</template>

<script>
import { merge } from 'lodash';
import Hotspot from './Hotspot';
import Modal from '../modal/Modal';

export default {
  name: 'RiddleHotspot',
  head() {
    return {
      script: [
        { src: 'https://www.riddle.com/files/js/embed.js', async: true },
      ],
    };
  },
  props: {
    sceneData: { type: Object },
    view: { type: Object },
    scene: { type: Object },
    design: { type: Object, default: () => ({}) },
    coords: { type: Object },
    //
    riddleId: { type: String },
  },
  components: { Hotspot, Modal },
  mounted() {
    this.scene.hotspotContainer().createHotspot(this.$refs.elem, this.coords, {
      perspective: {
        radius: 700,
        //extraTransforms: 'rotateX(80deg)',
      },
    });
  },
  beforeDestroy() {
    window.riddleAPI.riddles = [];
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    riddleUrl() {
      return `//www.riddle.com/a/${this.riddleId}?wide=1`;
    },
    mergedDesign() {
      return merge({ icon: 'mdi-help' }, this.design);
    },
  },
  methods: {
    handleClick() {
      this.$store.dispatch('visit', { type: 'riddle', id: this.riddleId });
      this.active = true;
    },
  },
};
</script>
