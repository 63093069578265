// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/patern.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/background.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".bg-image[data-v-2c74d890]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") repeat;box-shadow:inset 0 0 33px 0 rgba(0,0,0,.75)}.bg-image-2[data-v-2c74d890]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 50%;background-size:cover}", ""]);
// Exports
module.exports = exports;
