<template>
  <div ref="elem">
    <Hotspot :design="mergedDesign" @click="handleClick"> </Hotspot>
    <portal to="renderer">
      <div v-if="active" v-scroll-lock="false"></div>
      <Modal v-model="active">
        <div class="w-10/12 h-screen bg-white max-w-2xl relative">
          <div class="relative">
            <iframe
              v-if="active"
              class="w-full h-screen border-none"
              frameborder="0"
              :src="iframeSrc"
            ></iframe>
          </div>
        </div>
      </Modal>
    </portal>
  </div>
</template>

<script>
import { merge } from 'lodash';
import Hotspot from './Hotspot';
import Modal from '../modal/Modal';

export default {
  name: 'MarkdownHotspot',
  props: {
    sceneData: { type: Object },
    view: { type: Object },
    scene: { type: Object },
    design: { type: Object, default: () => ({}) },
    coords: { type: Object },
    //
    src: { type: String },
  },
  components: { Hotspot, Modal },
  mounted() {
    this.scene.hotspotContainer().createHotspot(this.$refs.elem, this.coords, {
      perspective: {
        radius: 700,
      },
    });
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    iframeSrc() {
      return (
        (process.env.VUE_APP_PUBLIC_PATH || '') +
        '/#/md?src=' +
        encodeURIComponent(this.src)
      );
    },
    mergedDesign() {
      return merge({ icon: 'mdi-book-open-variant' }, this.design);
    },
  },
  methods: {
    handleClick() {
      this.$store.dispatch('visit', { type: 'markdown', id: this.src });
      this.active = true;
    },
  },
};
</script>
