import Vue from 'vue';
import VScrollLock from 'v-scroll-lock';
import PortalVue from 'portal-vue';
import VueGtag from 'vue-gtag';

import routerConfig from './router';
import store from './store';
import i18nSetup from './plugins/i18n';
import './plugins/meta';
import './assets/main.css';
import fingerprint from './plugins/fp';
import '@mdi/font/css/materialdesignicons.css';

Vue.config.productionTip = false;
Vue.use(VScrollLock);
Vue.use(PortalVue);

const controller = (RootComponent, homeProps, locale = 'tr') => {
  const router = routerConfig(homeProps);
  Vue.use(
    VueGtag,
    {
      config: { id: 'G-JFZB9PWC1K' },
      globalObjectName: 'krgtag',
      pageTrackerTemplate(to) {
        // console.log(to);
        return {
          page_title: to.path,
          page_path: to.path,
        };
      },
    },
    router
  );

  const i18n = i18nSetup(locale);
  return {
    async start(options) {
      // console.log('start is called with options', options);
      const { fp } = await fingerprint();
      console.log(fp.visitorId);
      const vm = new Vue({
        router,
        store,
        i18n,
        render: (h) => h(RootComponent),
      });
      vm.$mount(options.el);

      return {
        stop() {
          // console.log('stop is called!, destroying..');
          vm.$destroy();
        },
      };
    },
  };
};

export default controller;
