<template>
  <div ref="elem">
    <Hotspot :design="mergedDesign" @click="handleClick"> </Hotspot>
  </div>
</template>

<script>
import { merge, cloneDeep } from 'lodash';
import GLightbox from 'glightbox';
import Hotspot from './Hotspot';

import 'glightbox/dist/css/glightbox.css';

export default {
  name: 'GalleryHotspot',
  props: {
    sceneData: { type: Object },
    view: { type: Object },
    scene: { type: Object },
    design: { type: Object, default: () => ({}) },
    coords: { type: Object },
    //
    elements: { type: Array, default: () => [] },
    galleryId: { type: String, default: '' },
  },
  components: { Hotspot },
  mounted() {
    this.scene.hotspotContainer().createHotspot(this.$refs.elem, this.coords, {
      perspective: {
        radius: 700,
      },
    });
    this.gl = GLightbox({
      elements: this.elements.map((i) => {
        const newi = cloneDeep(i);
        if (newi.href) {
          newi.href = newi.href.replace('{base}', this.dataPath + '/scenes');
        }
        return newi;
      }),
    });
  },
  data() {
    return {
      dataPath:
        localStorage.getItem('scenesPath') ||
        process.env.VUE_APP_SCENES_PATH ||
        '',
      gl: null,
    };
  },
  computed: {
    mergedDesign() {
      return merge({ icon: 'mdi-image-multiple' }, this.design);
    },
  },
  methods: {
    handleClick() {
      this.$store.dispatch('visit', { type: 'gallery', id: this.galleryId });
      this.gl.open();
      // console.log(this.scene.view.yaw(), this.scene.view.pitch());
    },
  },
};
</script>
