<template>
  <div ref="elem">
    <Hotspot
      :design="mergedDesign"
      @click="handleClick"
      @mouseenter.native="hover = true"
      @mouseleave.native="hover = false"
      class="relative group buttonGradient border-4 border-solid border-white py-1 px-3 cursor-pointer shadow-miniglow hover:shadow-glow transition-all rounded-full transform w-20 h-20 flex justify-center content-center items-center text-transparent hover:text-white"
    >
      <div
        class="border-4 border-solid border-white text-white bg-white bg-opacity-20 absolute top-0 right-0 -bottom-0 left-0 z-0 rounded-full animate-bounce flex justify-center align-center"
      >
        <i class="text-5xl mdi mdi-arrow-up-thick iconShadow" />
      </div>
    </Hotspot>
  </div>
</template>

<script>
import { merge } from 'lodash';
import Hotspot from './CleanHotspot';
export default {
  name: 'SceneHotspot',
  props: {
    sceneData: { type: Object },
    view: { type: Object },
    scene: { type: Object },
    design: { type: Object, default: () => ({}) },
    coords: { type: Object },
    //
    to: { type: String },
  },
  components: { Hotspot },
  mounted() {
    this.scene.hotspotContainer().createHotspot(this.$refs.elem, this.coords, {
      perspective: {
        radius: 500,
        extraTransforms: 'rotateX(80deg)',
      },
    });
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    mergedDesign() {
      return merge({ center: true }, this.design);
    },
  },
  methods: {
    calculateDegree(degree) {
      return (degree / 180 || 0) * Math.PI;
    },
    handleClick() {
      const scY =
        this.calculateDegree(this.sceneData.viewOffset.yaw) - this.view.yaw();
      const scP =
        this.calculateDegree(this.sceneData.viewOffset.pitch) +
        this.view.pitch();
      this.$router.push(`/pano/${this.to}?y=${scY}&p=${scP}`);
      // console.log(this.scene.view.yaw(), this.scene.view.pitch());
    },
  },
};
</script>

<style scoped>
.group:hover {
  text-shadow: 3px 3px 5px #e61448;
}
</style>
