// import en from './en'
// import tr from './tr'

export const availableLocales = ['en', 'tr'];

export default {
  en: {
    intro: {
      headline1: 'VIRTUAL CITY<br/> REAL COMPETITION',
      button1: 'EXPLORE NOW!',
      headline2: 'VIRTUAL CITY<br/> REAL COMPETITION',
      subhead2:
        'GET READY FOR A UNIQUE RED BULL M.E.O. WORLD FINALS EXPERIENCE IN A WHOLE NEW ISTANBUL.',
      button2: 'START',
    },
    map: {
      notify: 'IS LIVE NOW. CLICK HERE TO WATCH.',
      popupbar:
        'WELCOME TO ISTANBUL! THE CITY OF SEVEN HILLS AND HOME OF RED BULL M.E.O. WORLD FINALS. NOW CLICK ON A DISTRICT ON THE MAP TO DISCOVER WORLD OF RED BULL M.E.O.',
    },
    districts: {
      sultanahmet: 'SULTANAHMET',
      haydarpasa: 'HAYDARPASA',
      feribot: 'FERRY',
      kapalicarsi: 'GRAND BAZAAR',
      galata: 'GALATA',
      balat: 'BALAT',
      kopru: '15TH JULY Martyrs Bridge',
      caddebostan: 'CADDEBOSTAN',
      ortakoy: 'ORTAKOY',
      pubgwf: 'PUBG MOBILE WORLD FINALS',
    },
    toolbox: {
      exit: 'EXIT EXPERIENCE',
      calendar: 'CALENDAR',
      legend: 'LEGEND',
    },
    live: 'LIVE',
    drop: {
      intro: 'FIND ALL THE AIRDROPS!',
      info:
        'FIND THE 9 DIFFERENT AIRDROPS IN 9 DISTRICTS. COLLECT THE MEDALS IN THE TROPHY ROOM OF HAYDARPASA',
      next: 'NEXT',
      section_finish: "GGWP! <br /> YOU'VE COMPLETED THIS LOCATION.",
      sorry: 'SORRY, <br /> SOME OF YOUR MEDALS ARE MISSING.',
      go_back: 'GO BACK AND COMPLETE ALL THE DISTRICTS.',
      congrats: 'CONGRATS! <br /> YOU COMPLETED THE GAME SUCCESSFULLY!',
      trophy_room: 'COLLECT ALL THE MEDALS IN THE TROPHY ROOM OF HAYDARPASA.',
    },
    progress: {
      success:
        'CONGRATULATIONS! YOU HAVE DISCOVERED ALL REGIONS OF THE WORLD OF M.E.O.',
      info:
        'CHECK YOUR PROGRESS IN THE WORLD OF M.E.O. FROM THIS BAR. WHEN YOU COMPLETE ALL DISTRICTS, WE WILL BE WAITING FOR YOU TO GIVE YOU WINGS!',
    },
    trivia: {
      ready: 'READY TO PROVE YOUR KNOWLEDGE ABOUT VIRTUAL ISTANBUL?',
      info: 'ANSWER 9 QUESTIONS CORRECTLY AND TRY TO REACH THE HIGHEST FLOOR',
      better: 'YOU CAN DO IT BETTER!',
      tryagain: 'TRY AGAIN',
      congrats: 'CONGRATS! YOU COMPLETED THE GAME!',
      next: 'TAKE A SELFIE!',
      selfie: 'TAKE A SELFIE!',
      selfie_info:
        'LOCATE YOURSELF INTO THE BLANK SPACE OF THE PHOTO AND TAKE A SELFIE.',
      start: 'START',
    },
  },
  tr: {
    intro: {
      headline1: 'ŞEHİR SANAL<br/> REKABET GERÇEK',
      button1: 'KEŞFET',
      headline2: 'ŞEHİR SANAL<br/> REKABET GERÇEK',
      subhead2:
        'RED BULL M.E.O. DÜNYA FİNALLERİNİ BAMBAŞKA BİR İSTANBUL’DA DENEYİMLEMEYE HAZIR MISIN?',
      button2: 'BAŞLA',
    },
    map: {
      notify: 'ŞU ANDA CANLI YAYINDA. İZLEMEK İÇİN TIKLA.',
      popupbar:
        'RED BULL M.E.O. DÜNYA FİNALLERİ’NİN GERÇEKLEŞECEĞİ İSTANBUL’A HOŞ GELDİN! HARİTADA BULUNAN BÖLGELERDEN BİRİNE TIKLA VE RED BULL M.E.O. DÜNYASINI KEŞFET.',
    },
    districts: {
      sultanahmet: 'SULTANAHMET',
      haydarpasa: 'HAYDARPAŞA',
      feribot: 'FERİBOT',
      kapalicarsi: 'KAPALIÇARŞI',
      galata: 'GALATA',
      balat: 'BALAT',
      kopru: '15 TEMMUZ ŞEHİTLER KÖPRÜSÜ',
      caddebostan: 'CADDEBOSTAN',
      ortakoy: 'ORTAKÖY',
      pubgwf: 'PUBG MOBILE DÜNYA FİNALLERİ',
    },
    toolbox: {
      exit: 'DENEYİMDEN ÇIK',
      calendar: 'TAKVİM',
      legend: 'GEZİNME REHBERİ',
    },
    live: 'CANLI',
    drop: {
      intro: "AIRDROP'LARI BUL, SÜRPRİZ HEDİYELERE SAHİP OL!",
      info:
        "9 bölge İçerİsİnde gİzlenmİş airdropları bul. MadalyonLARI topladığında Haydarpaşa'da kupa odasında senİ beklİyor olacağız.",
      next: 'İLERLE',
      section_finish: 'TEBRİKLER! <br /> BU BÖLGEYİ TAMAMLADIN!',
      sorry: 'ÜZGÜNÜM, <br /> MADALYONLARINDAN BAZILARI EKSİK.',
      go_back: 'HADİ ŞİMDİ GERİ DÖN VE TÜM BÖLGELERİ TAMAMLA.',
      congrats: 'TEBRİKLER! <br /> OYUNU TAMAMLADIN!',
      share_info: 'SENİ KANATLANDIRMAMIZ İÇİN BİLGİLERİNİ PAYLAŞ.',
      fill_form: 'FORMU DOLDUR',
      trophy_room:
        "Tüm madalyonları topla ve Haydarpaşa'da yer alan kupa odasında ödül kaydını tamamla.",
    },
    progress: {
      form: 'FORMU DOLDUR',
      success:
        'TEBRİKLER! RED BULL M.E.O. HARİTASINDA YER ALAN TÜM BÖLGELERİ DOLAŞTIN. SENİ KANATLANDIRMAMIZ İÇİN BİZİMLE BİLGİLERİNİ PAYLAŞ.',
      info:
        'RED BULL M.E.O. SANAL İSTANBUL DENEYİMİNİ BU BAR ÜSTÜNDEN TAKİP ET. TÜM BÖLGELERİ DENEYİMLEMEYİ TAMAMLADIĞINDA SENİ KANATLANDIRMAK İÇİN BEKLİYOR OLACAĞIZ.',
    },
    trivia: {
      ready: "SANAL İSTANBUL'A NE KADAR HAKİM OLDUĞUNU GÖSTERMEYE HAZIR MISIN?",
      info: '9 SORUYU DOĞRU CEVAPLA. EN ÜST KATA ULAŞMAYA ÇALIŞ.',
      better: 'DAHA İYİSİNİ YAPABİLİRSİN!',
      tryagain: 'TEKRAR DENE',
      congrats: 'TEBRİKLER! OYUNU TAMAMLADIN!',
      next: 'İLERLE',
      share_info: 'SENİ KANATLANDIRABİLMEMİZ İÇİN BİLGİLERİNİ BİZİMLE PAYLAŞ.',
      form: 'FORMU DOLDUR',
      selfie: 'HATIRA FOTOĞRAFI ÇEKTİR',
      selfie_info:
        'AÇILACAK PENCEREDE KENDİNİ UYGUN ALAN İÇERİSİNE KONUMLANDIR VE HATIRA FOTOĞRAFINI ÇEK.',
      start: 'BAŞLA',
    },
  },
};
