<template>
  <div>
    <iframe
      v-if="enabled"
      :src="animAddress"
      class="w-screen h-screen fixed top-0 left-0 right-0 bottom-0 border-none pointer-events-none z-250"
    ></iframe>
  </div>
</template>

<script>
import find from 'lodash/find';

export default {
  name: 'SceneAnimations',
  components: {},

  data() {
    return {
      animAddress: '',
      enabled: false,
      prefix: (process.env.VUE_APP_PUBLIC_PATH || '') + '/animations/',
      animationMap: [
        {
          from: '/map',
          to: '/pano/Galata_01_CamekanSokak_r',
          address: 'galata.html',
        },
        {
          from: '/map',
          to: '/pano/haydarpasa_01-2_Tren',
          address: 'pubg.html',
        },
        {
          from: '/map',
          to: '/pano/Bogaz_koprusu_A01',
          address: 'default.html',
        },
        {
          from: '/map',
          to: '/pano/sultanahmet_01',
          address: 'default.html',
        },
        {
          from: '/map',
          to: '/pano/Caddebostan_01_Platform',
          address: 'default.html',
        },
        {
          from: '/map',
          to: '/pano/Feribot_02_onsahne',
          address: 'default.html',
        },
        {
          from: '/map',
          to: '/pano/ortakoy_02',
          address: 'tft.html',
          delay: 2500,
        },
        {
          from: '/map',
          to: '/pano/Kapalicarsi_01_HS_Game',
          address:
            this.$root.version === 'tr'
              ? 'hearthstone.html'
              : 'hearthstone_en.html',
        },
        {
          from: '/map',
          to: '/pano/Balata_02_Ekran_360',
          address: 'default.html',
        },
        {
          from: '/map',
          to: '/pano/Pubg_WF_Sahne',
          address: 'default.html',
        },
      ],
    };
  },
  head() {
    return {
      link: [
        ...this.animationMap.map((i) => {
          return {
            rel: 'prefetch',
            href: this.prefix + i.address,
            id: i.address,
          };
        }),
        ...this.animationMap.map((i) => {
          return {
            rel: 'preload',
            as: 'image',
            href: `${this.prefix}images/${i.address.replace(
              '.html',
              '_atlas_1.png'
            )}`,
          };
        }),
      ],
    };
  },
  created() {
    let closeTimeoutId;
    this.$router.beforeEach((to, from, next) => {
      const animation = find(this.animationMap, {
        from: from.path,
        to: to.path,
      });
      if (animation) {
        clearTimeout(closeTimeoutId);
        this.setupEventListener(next, animation.delay);
        this.animAddress = this.prefix + animation.address;
        this.enabled = true;
        closeTimeoutId = setTimeout(() => {
          this.enabled = false;
        }, 7000);
      } else {
        next();
      }
    });
  },
  methods: {
    setupEventListener(next, delay = 1000, timeout = 4000) {
      let timeoutId;
      const eventListener = (event) => {
        console.log('got:', event);
        if (event.data === 'startAnimation') {
          setTimeout(next, delay);
          window.removeEventListener('message', eventListener);
          clearTimeout(timeoutId);
        }
      };
      window.addEventListener('message', eventListener);
      timeoutId = setTimeout(() => {
        console.log('timedout');
        next();
        window.removeEventListener('message', eventListener);
      }, timeout);
    },
  },
};
</script>

<style></style>
