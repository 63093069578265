<template>
  <div ref="elem">
    <Hotspot :design="mergedDesign" @click="handleClick"> </Hotspot>
    <portal to="renderer">
      <Modal v-model="active">
        <div class="w-11/12 sm:w-10/12 relative">
          <div class="relative w-full aspect-w-16 aspect-h-9 overflow-hidden">
            <iframe
              v-if="active"
              class="absolute top-0 left-0 right-0 bottom-0 border-none"
              seamless="seamless"
              scrolling="no"
              :src="src"
            ></iframe>
          </div>
        </div>
      </Modal>
    </portal>
  </div>
</template>

<script>
import { merge } from 'lodash';
import Hotspot from './Hotspot';
import Modal from '../modal/Modal';

export default {
  name: 'VideoEmbedHotspot',
  props: {
    sceneData: { type: Object },
    view: { type: Object },
    scene: { type: Object },
    design: { type: Object, default: () => ({}) },
    coords: { type: Object },
    //
    src: { type: String },
  },
  components: { Hotspot, Modal },
  mounted() {
    this.scene.hotspotContainer().createHotspot(this.$refs.elem, this.coords, {
      perspective: {
        radius: this.design?.radius || 700,
      },
    });
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    mergedDesign() {
      return merge({ icon: 'mdi-play', class: 'transition-all' }, this.design);
    },
  },
  methods: {
    handleClick() {
      this.$store.dispatch('visit', { type: 'video', id: this.src });
      this.active = true;
    },
  },
};
</script>
