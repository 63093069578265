<template>
  <div class="w-full">
    <Popper
      :options="{ placement: 'bottom-start' }"
      enter-active-class="transition-all duration-1000"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
    >
      <div class="popper pt-2">
        <div
          class="w-full py-2 px-3 bg-brand-darkblue border-2 border-white border-solid rounded-xl text-xxs sm:text-xs font-subhead shadow-miniglow flex flex-col space-y-3"
        >
          <div v-if="percentage < 100">
            {{ $t('progress.info') }}
          </div>
          <div v-else class="flex flex-col items-center space-y-3">
            <div>
              {{ $t('progress.success') }}
            </div>
            <button
              v-if="$root.version === 'tr'"
              @click="form = true"
              class="font-display bg-white text-brand-darkblue py-1.5 px-3 rounded-lg text-sm"
            >
              {{ $t('progress.form') }}
            </button>
          </div>
        </div>
      </div>

      <div
        slot="reference"
        class="w-full bg-brand-blue bg-opacity-50 bg-image border-2 border-white border-solid shadow-miniglow rounded-full"
      >
        <div
          :class="[
            'bg-brand-red text-white text-xs py-1 sm:py-2 text-center rounded-full',
            percentage >= 100 && 'animate-pulse',
          ]"
          :style="progressStyle"
        >
          {{ percentage }}%
        </div>
      </div>
    </Popper>
    <portal to="renderer">
      <Modal v-model="form">
        <div
          v-if="form"
          class="w-11/12 sm:w-10/12 max-w-2xl h-screen py-10 flex justify-center items-center relative"
        >
          <iframe
            class="w-full h-full border-none"
            frameborder="0"
            src="https://redbull.jotform.com/form/210663657984064"
          ></iframe>
        </div>
      </Modal>
    </portal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Popper from 'vue-popperjs';
import Modal from '@/components/modal/Modal';

export default {
  name: 'ProgressBar',
  components: { Popper, Modal },
  data() {
    return {
      form: false,
    };
  },
  computed: {
    ...mapGetters(['progressValue']),
    percentage() {
      // return 100;
      return (this.progressValue * 100).toFixed(0);
    },
    progressStyle() {
      return { minWidth: '20%', width: `${this.percentage}%` };
    },
  },
  methods: {
    onClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style scoped>
.bg-image {
  /* background: url('../assets/patern.jpg') repeat; */
  box-shadow: inset 0px 0px 33px 0px rgba(0, 0, 0, 0.75);
}
</style>
