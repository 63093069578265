<template>
  <div
    :class="hotspotClasses"
    @click="$emit('click', $event)"
    :style="hotspotStyle"
  >
    <div
      v-if="design.tooltip"
      :class="[
        'absolute opacity-0 pointer-events-none group-hover:opacity-100 top-1/2 w-48 transform -translate-y-1/2 flex',
        design.tooltipInverse
          ? '-ml-2 left-0 -translate-x-full justify-end'
          : '-mr-4 right-0 translate-x-full justify-start',
      ]"
    >
      <div
        class="text-xs bg-gray-800 bg-opacity-80 rounded-lg px-3 py-1 text-white z-250"
      >
        {{ tooltip }}
      </div>
    </div>

    <i
      v-if="!design.reset && center"
      :class="['iconShadow', 'text-3xl', 'mdi', design.icon || 'mdi-arrow-up']"
    />
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Hotspot',
  props: {
    design: { type: Object, default: () => ({}) },
  },
  components: {},
  data() {
    return {
      defaultClasses:
        'relative group w-14 h-14 flex justify-center content-center items-center buttonGradient text-white border-solid border-white border-2 py-1 px-3 cursor-pointer shadow-miniglow hover:bg-primary-active hover:shadow-glow transition-all rounded-full transform z-200',
    };
  },
  computed: {
    tooltip() {
      if (this.$root.version === 'tr') {
        return this.design.tooltip.replaceAll('i', 'İ').toUpperCase();
      }
      return this.design.tooltip;
    },
    center() {
      return typeof this.design.center === 'undefined'
        ? true
        : this.design.center;
    },
    hotspotClasses() {
      return [
        // this.center && '-translate-y-1/2 -translate-x-1/2 text-white',
        !this.design.reset && this.defaultClasses,
        this.design.class,
      ];
    },
    hotspotStyle() {
      return Object.assign({}, this.design?.style);
    },
  },
};
</script>
