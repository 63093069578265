<template>
  <div>
    <div
      v-html="render"
      class="mx-auto px-2 sm:px-4 pb-4 prose-sm sm:prose font-sans text-black"
    />
  </div>
</template>

<script>
import axios from 'axios';
import * as mdit from 'markdown-it';

const md = mdit({
  html: true,
  linkify: true,
  typographer: true,
});

const dataPath =
  localStorage.getItem('scenesPath') || process.env.VUE_APP_SCENES_PATH || '';

export default {
  name: 'MDView',
  components: {},
  created() {
    const src = this.$route.query.src;
    axios.get(`${dataPath}/scenes/${src}`).then(({ data }) => {
      this.text = data;
    });
  },
  data() {
    return {
      text: '',
    };
  },
  computed: {
    render() {
      return md.render(this.text);
    },
  },
  methods: {
    handleClick() {},
  },
};
</script>
