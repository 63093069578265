<template>
  <Popper :disabled="!tooltip" :options="{ placement: 'right' }">
    <div
      class="popper ml-2 whitespace-nowrap text-sm bg-gray-800 bg-opacity-80 rounded px-3 py-1 text-white"
    >
      {{ tooltip }}
    </div>
    <button
      slot="reference"
      class="w-7 sm:w-10 h-7 sm:h-10 iconShadow flex justify-center content-center items-center rounded-full buttonGradient buttonTextShadow border-2 border-solid border-white text-white text-lg lg:text-xl font-black shadow-miniglow hover:shadow-glow transition-all transform focus:outline-none focus:ring-0"
      @click="onClick"
    >
      <slot />
    </button>
  </Popper>
</template>

<script>
import Popper from 'vue-popperjs';

export default {
  name: 'ToolButton',
  props: ['tooltip'],
  components: { Popper },
  methods: {
    onClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>
