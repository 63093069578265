<template>
  <div class="fixed top-12 sm:top-16 left-3 sm:left-5 flex flex-col space-y-4">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Toolbox',
  methods: {},
};
</script>

<style></style>
