<template>
  <button
    @click="$emit('click', $event)"
    class="absolute right-0 top-0 -mr-5 -mt-5 w-10 h-10 bg-gray-800 text-gray-400 bg-opacity-50 rounded-full hover:bg-opacity-70 hover:text-gray-50 flex justify-center items-center focus:outline-none"
  >
    <i class="mdi mdi-close text-3xl text-white" />
  </button>
</template>

<script>
export default {
  name: 'CloseButton',
};
</script>

<style></style>
