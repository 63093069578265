<template>
  <div>
    <ToolButton :tooltip="$t('toolbox.legend')" @click="gl.open()">
      <i class="mdi mdi-information" />
    </ToolButton>
  </div>
</template>

<script>
import ToolButton from './ToolButton';
import GLightbox from 'glightbox';

import 'glightbox/dist/css/glightbox.css';

export default {
  name: 'Legend',
  components: { ToolButton },
  mounted() {
    this.gl = GLightbox({
      elements: this.elements[this.$root.version],
    });
  },
  data() {
    return {
      gl: null,
      elements: {
        tr: [
          {
            href: require('@/assets/iconlar.png'),
            type: 'image',
          },
        ],
        en: [
          {
            href: require('@/assets/iconlar_ing.png'),
            type: 'image',
          },
        ],
      },
    };
  },
  methods: {
    onClick() {},
  },
};
</script>

<style></style>
