<template>
  <button
    class="bg-white rounded-xl text-gray-800 px-6 md:px-8 py-1 md:py-2 text-lg md:text-xl xl:text-2xl 2xl:text-3xl font-black shadow-miniglow hover:shadow-glow transition-all transform hover:-translate-y-0.5 focus:outline-none focus:ring-0"
    @click="onClick"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: ['to'],
  methods: {
    onClick(e) {
      this.$emit('click', e);
      if (this.to) {
        this.$router.push(this.to);
      }
    },
  },
};
</script>
