<template>
  <div ref="elem">
    <Hotspot :design="mergedDesign" @click="handleClick"> </Hotspot>
    <portal to="renderer">
      <Modal :close="false" v-model="active">
        <div class="w-11/12 sm:w-5/12 md:w-8/12 lg:w-5/12 max-w-2xl">
          <div class="aspect-w-10 aspect-h-12 relative">
            <div
              v-if="step < 6"
              class="absolute top-0 left-0 right-0 bottom-0 bg-center bg-contain bg-no-repeat p-8 text-brand-brown"
              :style="`background-image: url('${require('@/assets/trivia-bg.png')}')`"
            >
              <div
                @click="step = 2"
                v-if="step === -1"
                class="w-full h-full flex flex-col justify-center items-center space-y-4 cursor-pointer"
              >
                <img
                  :src="require('@/assets/logo.png')"
                  class="w-24 h-auto object-contain"
                />
                <div class="text-center text-2xl md:text-4xl px-2">
                  {{ $t('trivia.better') }}
                </div>
                <button class="rounded-lg bg-brand-brown text-white py-2 px-4">
                  {{ $t('trivia.tryagain') }}
                </button>
              </div>
              <div
                @click="step = 2"
                v-if="step === 0"
                class="w-full h-full flex flex-col justify-center items-center md:space-y-4 cursor-pointer"
              >
                <div
                  class="text-center text-lg sm:text-sm md:text-xl lg:text-2xl xl:text-4xl px-2 md:px-4"
                >
                  {{ $t('trivia.ready') }}
                </div>
                <div
                  class="text-center text-lg sm:text-sm md:text-xl lg:text-2xl xl:text-4xl px-2 md:px-4"
                >
                  {{ $t('trivia.info') }}
                </div>
                <img
                  :src="require('@/assets/playlogo.png')"
                  class="w-24 sm:w-48 h-auto object-contain cursor-pointer"
                />
              </div>
              <div
                v-if="step === 2"
                class="w-full h-full flex flex-col justify-center items-center space-y-4"
              >
                <div
                  class="text-center text-lg sm:text-sm md:text-xl lg:text-2xl xl:text-3xl px-2 leading-tight sm:leading-none md:leading-tight"
                >
                  {{ trChar(selection[currentIndex].q) }}
                </div>
                <div
                  class="text-lg sm:text-sm md:text-xl lg:text-2xl xl:text-3xl w-full px-2 md:px-4 space-y-3"
                >
                  <button
                    v-for="a in sampleSize(selection[currentIndex].a, 2)"
                    :key="a"
                    @click="sendAnswer(a)"
                    class="block w-full text-center rounded-lg bg-brand-brown text-white py-1 md:py-2 px-2 md:px-4"
                  >
                    {{ trChar(a) }}
                  </button>
                </div>
              </div>
              <div
                @click="$root.version === 'tr' ? (step = 4) : (step = 5)"
                v-if="step === 3"
                class="w-full h-full flex flex-col justify-center items-center space-y-4 cursor-pointer"
              >
                <img
                  :src="require('@/assets/logo.png')"
                  class="w-24 h-auto object-contain"
                />
                <div class="text-center text-4xl px-2">
                  {{ $t('trivia.congrats') }}
                </div>
                <button class="rounded-lg bg-brand-brown text-white py-2 px-4">
                  {{ $t('trivia.next') }}
                </button>
              </div>
              <div
                v-if="step === 4"
                class="w-full h-full flex flex-col justify-center items-center space-y-4 cursor-pointer"
              >
                <img
                  :src="require('@/assets/logo.png')"
                  class="w-12 md:w-24 h-auto object-contain"
                />
                <div class="text-center text-xl md:text-4xl px-2 md:px-5">
                  {{ $t('trivia.share_info') }}
                </div>
                <div class="space-y-4">
                  <button
                    @click="step = 7"
                    class="block w-full text-center rounded-lg border-brand-brown border-solid border-2 text-brand-brown py-1 sm:py-2 px-2 sm:px-4"
                  >
                    {{ $t('trivia.form') }}
                  </button>
                  <button
                    @click="step = 5"
                    class="block w-full text-center rounded-lg bg-brand-brown text-white py-1 md:py-2 px-2 md:px-4"
                  >
                    {{ $t('trivia.selfie') }}
                  </button>
                </div>
              </div>
              <div
                v-if="step === 5"
                class="w-full h-full flex flex-col justify-center items-center space-y-4 cursor-pointer"
              >
                <div class="text-center text-2xl sm:text-4xl px-2">
                  {{ $t('trivia.selfie_info') }}
                </div>
                <div>
                  <button
                    @click="openSelfie"
                    class="block w-full text-center rounded-lg bg-brand-brown text-white py-1 md:py-2 px-2 md:px-4"
                  >
                    {{ $t('trivia.start') }}
                  </button>
                </div>
              </div>
              <CloseButton @click="active = false" />
            </div>
            <div
              v-if="step === 6"
              class="w-full h-full flex flex-col justify-center items-center space-y-4 cursor-pointer"
            >
              <iframe
                :src="`https://redbull-meo-photobooth.kraftend.dev/${$root.version}`"
                class="w-full h-full"
                frameborder="0"
                allow="camera"
              />
              <CloseButton @click="step = 4" />
            </div>
            <div
              v-if="step === 7"
              class="w-full h-full flex flex-col justify-center items-center space-y-4 cursor-pointer"
            >
              <iframe
                src="https://redbull.jotform.com/form/210663657984064"
                class="w-full h-full"
                frameborder="0"
              />
              <CloseButton @click="step = 4" />
            </div>
          </div>
        </div>
      </Modal>
    </portal>
  </div>
</template>

<script>
import { merge, sampleSize } from 'lodash';
import Hotspot from './Hotspot';
import Modal from '../modal/Modal';
import CloseButton from '../modal/CloseButton';
import trivia from '@/assets/trivia';
import { isMobile } from '@/assets/ismobile';

export default {
  name: 'Trivia',
  props: {
    sceneData: { type: Object },
    view: { type: Object },
    scene: { type: Object },
    design: { type: Object, default: () => ({}) },
    coords: { type: Object },
    //
  },
  components: { Hotspot, CloseButton, Modal },
  mounted() {
    this.scene.hotspotContainer().createHotspot(this.$refs.elem, this.coords, {
      perspective: {
        radius: 700,
      },
    });
  },
  created() {},
  data() {
    return {
      active: false,
      questions: trivia[this.$root.version],
      currentIndex: 0,
      step: 0,
      seed: 0,
    };
  },
  computed: {
    mergedDesign() {
      return merge({ icon: 'mdi-help' }, this.design);
    },
    selection() {
      return sampleSize(this.questions, 9, this.seed);
    },
  },
  methods: {
    openSelfie() {
      if (isMobile()) {
        window.open(
          `https://redbull-meo-photobooth.kraftend.dev/${this.$root.version}`
        );
      } else {
        this.step = 6;
      }
    },
    sampleSize,
    handleClick() {
      this.$store.dispatch('visit', { type: 'trivia', id: 'galata' });
      this.active = true;
    },
    sendAnswer(a) {
      if (a === this.selection[this.currentIndex].a[0]) {
        if (this.currentIndex === 8) {
          this.step += 1;
        } else {
          this.currentIndex += 1;
        }
      } else {
        this.seed += 1;
        this.currentIndex = 0;
        this.step = -1;
      }
    },
    trChar(str) {
      if (this.$root.version === 'tr') {
        return str.replaceAll('i', 'İ');
      }
      return str;
    },
  },
};
</script>
