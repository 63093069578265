<template>
  <div class="relative" ref="pc" v-scroll-lock="true">
    <MZPRenderer
      :design="design"
      :yaw="$route.query.y"
      :pitch="$route.query.p"
    />
    <Toolbox>
      <BackButton to="back" />
      <MapNavigation />
    </Toolbox>
    <div
      class="fixed top-12 sm:top-16 right-3 sm:right-5 flex flex-row justify-end w-32 sm:w-64 space-y-4"
    >
      <ProgressBar />
    </div>
  </div>
</template>

<script>
import MZPRenderer from '@/components/MZPRenderer';
import BackButton from '@/components/BackButton';
import MapNavigation from '@/components/MapNavigation';
import Toolbox from '@/components/Toolbox';
import ProgressBar from '@/components/ProgressBar';

export default {
  name: 'Panorama',
  components: { MZPRenderer, MapNavigation, BackButton, Toolbox, ProgressBar },
  data() {
    return {};
  },
  created() {
    window.scroll(0, 0);
    window.addEventListener('orientationchange', this.onOrientationChange);
  },
  beforeDestroy() {
    window.removeEventListener('orientationchange', this.onOrientationChange);
  },
  computed: {
    design() {
      return this.$route.params?.design;
    },
  },
  methods: {
    onOrientationChange() {
      /*
      const angle = event.target.screen.orientation.angle;
      if (angle === 90 || angle === 270) {
        this.$refs.pc.requestFullscreen({ navigationUI: 'hide' });
      } else if (document.fullscreenEnabled) {
        document.exitFullscreen();
      }
      console.log(
        'the orientation of the device is now ' +
          event.target.screen.orientation.angle
      );
      */
    },
  },
};
</script>

<style></style>
