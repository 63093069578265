<template>
  <div v-if="active && imgSrc">
    <!-- <div class="fixed top-0 left-0 right-0 bottom-0 gradient-redbull blend" /> -->
    <Modal :background="true" v-model="active">
      <div
        @click="active = false"
        class="absolute cursor-pointer top-0 left-0 right-0 bottom-0 m-5 sm:m-12 md:m-18 xl:m-24 2xl:m-32 bg-contain bg-no-repeat bg-center"
        :style="{ backgroundImage: `url(${pathPrefix}${imgSrc})` }"
      ></div>
    </Modal>
  </div>
</template>

<script>
import Modal from './Modal';

export default {
  name: 'SceneInfo',
  props: ['intro', 'pathPrefix'],
  components: { Modal },
  data() {
    return {
      active: true,
    };
  },
  computed: {
    imgSrc() {
      if (typeof this.intro === 'string') {
        return this.intro;
      }
      if (typeof this.intro === 'object') {
        return this.intro[this.$root.version];
      }
      return null;
    },
  },
  created() {},
};
</script>

<style></style>
