<template>
  <div ref="elem">
    <div v-if="currentItem" class="text-xs w-32">
      {{ currentTitle }} <br />
      <br />
      {{ streamDate }}<br />{{ streamTime }} <br /><br />
      <VueCountdown :time="countdown">
        <template slot-scope="props">
          <div class="font-lg">
            {{ padStart(props.days) }}:{{ padStart(props.hours) }}:{{
              padStart(props.minutes)
            }}:{{ padStart(props.seconds) }}
          </div>
        </template>
      </VueCountdown>
    </div>
  </div>
</template>

<script>
import { find } from 'lodash';
import { DateTime } from 'luxon';
import VueCountdown from '@chenfengyuan/vue-countdown';
import calendar from '@/assets/calendar';

export default {
  name: 'CountdownHotspot',
  head() {
    return {};
  },
  props: {
    sceneData: { type: Object },
    sceneName: { type: String },
    view: { type: Object },
    scene: { type: Object },
    design: { type: Object, default: () => ({}) },
    coords: { type: Object },
    //
  },
  components: { VueCountdown },
  mounted() {
    this.scene.hotspotContainer().createHotspot(this.$refs.elem, this.coords, {
      perspective: {
        radius: 700,
        extraTransforms: 'rotateZ(-1deg) rotateY(-2deg)',
      },
    });
    console.log(this.currentItem);
  },
  data() {
    return {
      active: false,
      calendar,
    };
  },
  computed: {
    currentItem() {
      return find(this.calendar, (i) => {
        return (
          i.location === this.sceneName &&
          //Date.now() >=
          //  new Date(i.startTime).getTime() -
          //   24 * 60 * 60 * 1000 +
          //   15 * 60 * 1000 &&
          Date.now() <= new Date(i.startTime).getTime() + 15 * 60 * 1000
        );
      });
    },
    currentTitle() {
      if (!this.currentItem) return null;
      return this.getLangProp(this.currentItem.title);
    },
    countdown() {
      if (!this.currentItem) {
        return 0;
      }
      return (
        new Date(this.currentItem.startTime).getTime() -
        Date.now() +
        15 * 60 * 1000
      );
    },
    streamDate() {
      if (!this.currentItem) {
        return null;
      }

      return DateTime.fromISO(this.currentItem.startTime)
        .plus({ minutes: 15 })
        .toLocaleString(DateTime.DATE_MED);
    },
    streamTime() {
      if (!this.currentItem) {
        return null;
      }

      return DateTime.fromISO(this.currentItem.startTime)
        .plus({ minutes: 15 })
        .toLocaleString(DateTime.TIME_24_SIMPLE);
    },
  },
  methods: {
    padStart(val) {
      return String(val).padStart(2, '0');
    },
    getLangProp(prop) {
      if (typeof prop === 'string') {
        return prop;
      }
      if (typeof prop === 'object') {
        return prop[this.$root.version];
      }
      return null;
    },
  },
};
</script>
