<template>
  <ToolButton :tooltip="tooltip" @click="onClick">
    <i class="mdi mdi-arrow-left-thick" />
  </ToolButton>
  <!--
  <Popper :options="{ placement: 'right' }">
    <div
      class="popper ml-2 text-sm bg-gray-800 bg-opacity-80 rounded px-3 py-1 text-white"
    >
      {{ tooltip }}
    </div>
    <button
      slot="reference"
      class="w-7 sm:w-8 h-7 sm:h-8 flex justify-center content-center items-center rounded-full bg-indigo-900 text-lg sm:text-xl text-white shadow-lg hover:shadow-xl hover:bg-indigo-800 focus:outline-none"
      @click="onClick"
    >
      <i class="mdi mdi-arrow-left" />
    </button>
  </Popper>
  -->
</template>

<script>
import ToolButton from './ToolButton';

export default {
  name: 'BackButton',
  props: ['to', 'tooltip'],
  components: { ToolButton },
  methods: {
    onClick(e) {
      this.$emit('click', e);
      if (this.to === 'back') {
        this.$router.back();
      } else if (this.to) {
        this.$router.push(this.to);
      }
    },
  },
};
</script>

<style></style>
