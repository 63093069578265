import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages, { availableLocales } from '../locales';

Vue.use(VueI18n);

export default function (locale) {
  return new VueI18n({
    locale,
    fallbackLocale: 'tr',
    availableLocales: availableLocales,
    messages,
  });
}
