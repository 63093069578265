<template>
  <div>
    <div
      class="fixed mx-5 bottom-5 sm:bottom-10 sm:right-10"
      v-if="item && !hidden"
    >
      <div
        @click="
          $router.push({
            path: `/pano/${item.location}`,
            query: { find: 'twitch' },
          })
        "
        :key="item.startTime"
        class="bg-white cursor-pointer max-w-xs p-3 sm:p-4 font-subhead text-brand-darkblue shadow-xl rounded-xl text-xxs sm:text-xs"
      >
        <span class="text-brand-red uppercase">
          {{ getLangProp(item.title) }}</span
        >
        {{ $t('map.notify') }}
        <button
          @click="handleCLose"
          class="absolute right-0 top-0 -mr-5 -mt-5 w-10 h-10 bg-gray-800 text-gray-400 bg-opacity-50 rounded-full hover:bg-opacity-70 hover:text-gray-50 flex justify-center items-center focus:outline-none"
        >
          <i class="mdi mdi-close text-3xl text-white" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { find } from 'lodash';
import calendar from '@/assets/calendar';

export default {
  name: 'CalNotifications',
  components: {},
  mounted() {},
  data() {
    return {
      calendar,
      hidden: false,
    };
  },
  computed: {
    item() {
      return find(this.calendar, (i) => {
        return (
          Date.now() >= new Date(i.startTime).getTime() &&
          Date.now() <= new Date(i.endTime).getTime()
        );
      });
    },
  },
  methods: {
    handleCLose(e) {
      e.preventDefault();
      e.stopPropagation();
      this.hidden = true;
    },
    getLangProp(prop) {
      if (typeof prop === 'string') {
        return prop;
      }
      if (typeof prop === 'object') {
        return prop[this.$root.version];
      }
      return null;
    },
    onClick() {},
  },
};
</script>

<style></style>
