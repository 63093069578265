<template>
  <div class="relative">
    <div
      ref="panoContainer"
      class="panoContainer"
      @contextmenu="rightClickHandler"
    />
    <div
      v-if="editorMode"
      class="absolute font-sans top-0 left-0 right-0 bg-gray-800 flex justify-between items-center text-white text-xs px-3 h-8"
    >
      <div class="w-1/3 flex items-center align-center justify-start space-x-4">
        <div class="font-semibold">EDITOR MODE</div>
        <Popper
          :options="{
            placement: 'bottom-end',
          }"
        >
          <button
            slot="reference"
            class="py-1 px-2 bg-gray-900 focus:outline-none"
          >
            <i class="mdi mdi-format-list-bulleted-square text-base" />
          </button>
          <div class="popper pt-1 z-250">
            <div
              class="bg-white rounded text-gray-700 py-2 max-h-96 overflow-y-auto"
            >
              <router-link
                :to="`/pano/${sceneName}`"
                v-for="sceneName in sceneList"
                :key="sceneName"
                class="block py-1 px-4 hover:bg-gray-100 hover:text-gray-900 font-medium text-xs"
              >
                {{ sceneName }}
              </router-link>
            </div>
          </div>
        </Popper>
        <div class="text-gray-400">
          {{ dataPath }}
        </div>
      </div>
      <div class="w-1/3 text-center">
        {{ design }}
      </div>
      <div class="w-1/3">
        <div v-if="isModified" class="flex justify-end space-x-4 items-center">
          <div class="text-yellow-400">
            <i class="mdi mdi-alert mr-2" />
            <span class="font-semibold">MODIFIED DATA</span>
          </div>
          <button
            @click="handleCopy"
            class="border border-gray-500 rounded text-gray-300 px-2 py-0.5 font-medium"
          >
            <i class="mdi mdi-content-copy mr-1" />
            COPY
          </button>
          <button
            v-if="false"
            @click="handleDownload"
            class="border border-gray-500 rounded text-gray-300 px-2 py-0.5 font-medium"
          >
            <i class="mdi mdi-download mr-1" />
            DOWNLOAD
          </button>
        </div>
      </div>
    </div>
    <div>
      <template v-if="!loading">
        <component
          v-for="(hs, index) in hotspots"
          :is="types[hs.type]"
          :sceneName="design"
          :scene="scene"
          :view="view"
          :sceneData="sceneData"
          v-bind="hs"
          :key="`hs_${index}`"
          :ref="`hs_${index}`"
        />
      </template>
    </div>
    <div
      v-show="false && loading"
      class="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-blue-900"
    >
      LOADING
    </div>
    <portal to="renderer">
      <Modal v-if="newHotspotModal" v-model="newHotspotModal">
        <HotspotSettings
          @cancel="newHotspotModal = false"
          @save="handleNewSave"
          :coords="newHotspot.coords"
        />
      </Modal>
      <SceneIntro
        v-if="firstVisit && sceneData.intro && !loading"
        :pathPrefix="`${dataPath}/scenes/`"
        :intro="sceneData.intro"
        :key="sceneData.intro[$root.version]"
      />
    </portal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Marzipano from 'marzipano';
import axios from 'axios';
import { cloneDeep, filter, find } from 'lodash';
import isEqual from 'fast-deep-equal';
import Popper from 'vue-popperjs';

import Modal from '@/components/modal/Modal';
import HotspotSettings from '@/components/modal/HotspotSettings';
import SceneHotspot from '@/components/hotspots/SceneHotspot';
import SceneVerticalHotspot from '@/components/hotspots/SceneVerticalHotspot';
import VideoEmbedHotspot from '@/components/hotspots/VideoEmbedHotspot';
import IframeHotspot from '@/components/hotspots/IframeHotspot';
import RiddleHotspot from '@/components/hotspots/RiddleHotspot';
import TwitchHotspot from '@/components/hotspots/TwitchHotspot';
import MarkdownHotspot from '@/components/hotspots/MarkdownHotspot';
import DropKupaHotspot from '@/components/hotspots/DropKupaHotspot';
import GalleryHotspot from '@/components/hotspots/GalleryHotspot';
import SquareIframeHotspot from '@/components/hotspots/SquareIframe';
import GalataTrivia from '@/components/hotspots/GalataTrivia';
import CountdownHotspot from '@/components/hotspots/CountdownHotspot';
import Link from '@/components/hotspots/Link';
import SceneIntro from '@/components/modal/SceneIntro';
/*
import InfoHotspot from '@/components/hotspots/InfoHotspot';
import LinkHotspot from '@/components/hotspots/LinkHotspot';
import LightboxHotspot from '@/components/hotspots/LightboxHotspot';
import FileHotspot from '@/components/hotspots/FileHotspot';
*/
export default {
  name: 'MZPRenderer',
  components: {
    Popper,
    Modal,
    HotspotSettings,
    SceneHotspot,
    RiddleHotspot,
    VideoEmbedHotspot,
    IframeHotspot,
    SceneIntro,
    TwitchHotspot,
    DropKupaHotspot,
    GalleryHotspot,
    SquareIframeHotspot,
    GalataTrivia,
    Link,
    CountdownHotspot,
  },
  props: {
    design: {
      type: String,
    },
    yaw: { type: String },
    pitch: { type: String },
  },
  data() {
    return {
      firstVisit: false,
      newHotspotModal: false,
      newHotspot: {},
      loading: true,
      editorMode: !!localStorage.getItem('scenesPath'),
      dataPath:
        localStorage.getItem('scenesPath') ||
        process.env.VUE_APP_SCENES_PATH ||
        '',
      sceneData: {},
      originalSceneData: {},
      types: {
        scene: SceneHotspot,
        riddle: RiddleHotspot,
        video: VideoEmbedHotspot,
        iframe: IframeHotspot,
        sceneVertical: SceneVerticalHotspot,
        markdown: MarkdownHotspot,
        twitch: TwitchHotspot,
        drop: DropKupaHotspot,
        gallery: GalleryHotspot,
        square: SquareIframeHotspot,
        link: Link,
        trivia: GalataTrivia,
        countdown: CountdownHotspot,
        /* info: InfoHotspot,
        link: LinkHotspot,
        lightbox: LightboxHotspot,
        files: FileHotspot, */
      },
      imgUrlPrefix:
        (localStorage.getItem('cdnPath') ||
          process.env.VUE_APP_CDN_PATH ||
          '') + '/tiles',
      viewer: null,
      viewerOpts: {
        mouseViewMode: 'drag',
      },
      autorotate: Marzipano.autorotate({
        yawSpeed: 0.03,
        targetPitch: 0,
        targetFov: Math.PI / 2,
      }),
      view: null,
      scene: null,
      controls: null,
      controlVelocity: 0.4,
      controlFriction: 3,
    };
  },
  mounted() {
    setTimeout(() => {
      this.initPano();
    }, 100);
  },
  beforeDestroy() {
    this.unregisterControls();
    // this.controls.destroy();
    this.viewer.destroy();
  },
  computed: {
    isModified() {
      if (this.editorMode) {
        return !isEqual(this.sceneData, this.originalSceneData);
      }
      return false;
    },
    ...mapState({
      sceneList: (s) => s.scenes,
    }),
    ...mapGetters(['isVisited']),
    revisit() {
      return this.isVisited('scene:' + this.design);
    },
    hotspots() {
      if (!this.sceneData) {
        return [];
      }
      return filter(this.sceneData.hotspots, (o) => {
        if (
          [
            'scene',
            'sceneVertical',
            'drop',
            'twitch',
            'trivia',
            'countdown',
          ].includes(o.type)
        ) {
          return true;
        }
        if (o.lang === 'all') {
          return true;
        }
        if (this.$root.version === o.lang) {
          return true;
        }
        if (this.$root.version === 'tr' && !o.lang) {
          return true;
        }
        return false;
      });
    },
  },
  watch: {
    design(nv, ov) {
      if (nv && nv !== ov) {
        return this.loadPano();
      }
    },
  },
  methods: {
    calculateDegree(degree) {
      return (degree / 180 || 0) * Math.PI;
    },
    handleCopy() {
      const string = JSON.stringify(this.sceneData, null, 2);
      navigator.clipboard.writeText(string);
    },
    rightClickHandler(e) {
      if (this.view && this.editorMode) {
        e.preventDefault();
        this.newHotspot = {
          coords: this.view.screenToCoordinates({
            x: e.clientX,
            y: e.clientY,
          }),
        };
        this.newHotspotModal = true;
      }
    },
    handleNewSave(data) {
      this.sceneData.hotspots.push(data);
      this.newHotspot = {};
      this.newHotspotModal = false;
    },
    async loadPano() {
      this.loading = true;
      let sceneData = {
        name: 'Unknown Place',
        viewOffset: {
          yaw: 0,
          pitch: 0,
        },
        initialView: {
          yaw: 0,
          pitch: 0,
          fov: 1.57079632679,
        },
        hotspots: [],
      };
      console.log('isVisited:', this.revisit);
      this.firstVisit = !this.revisit;
      this.$store.dispatch('visit', { type: 'scene', id: this.design });
      try {
        const { data } = await axios.get(
          `${this.dataPath}/scenes/${this.design}.json`
        );
        sceneData = Object.assign(sceneData, data);
      } catch (e) {
        console.error('scene data cannot load');
      }
      const { data: levelsData } = await axios.get(
        `${this.imgUrlPrefix}/${this.design}/levels.json`
      );

      this.sceneData = sceneData;
      if (this.editorMode) {
        this.originalSceneData = cloneDeep(sceneData);
      }

      const source = Marzipano.ImageUrlSource.fromString(
        `${this.imgUrlPrefix}/${this.design}/{f}/{z}/{y}/{x}.jpg`
      );
      const geometry = new Marzipano.CubeGeometry(levelsData);
      const limiter = Marzipano.RectilinearView.limit.traditional(
        levelsData[levelsData.length - 1].size, //facesize
        (100 * Math.PI) / 180,
        (120 * Math.PI) / 180
      );

      const view = new Marzipano.RectilinearView(
        sceneData.initialView,
        limiter
      );
      this.view = view;

      const scene = this.viewer.createScene({
        source,
        geometry,
        view,
        pinFirstLevel: true,
      });
      this.scene = scene;

      view.setParameters({
        yaw:
          this.calculateDegree(sceneData.viewOffset.yaw) -
          parseFloat(this.yaw || sceneData.initialView.yaw || '0'),
        pitch:
          this.calculateDegree(sceneData.viewOffset.pitch) +
          parseFloat(this.pitch || sceneData.initialView.pitch || '0'),
        fov: sceneData.initialView.fov,
      });
      scene.switchTo();

      if (this.$route.query.find === 'twitch') {
        const ths = find(this.sceneData.hotspots, { type: 'twitch' });
        if (ths) {
          view.setYaw(ths.coords.yaw);
          view.setPitch(ths.coords.pitch);
        }
      }
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 150);
      this.loading = false;
    },
    initPano() {
      this.viewer = new Marzipano.Viewer(
        this.$refs.panoContainer,
        this.viewerOpts
      );

      this.controls = this.viewer.controls();
      this.registerControls();

      this.loadPano();
    },
    stopAutorotate() {
      this.viewer.stopMovement();
      this.viewer.setIdleMovement(Infinity);
    },
    startAutorotate() {
      this.viewer.startMovement(this.autorotate);
      this.viewer.setIdleMovement(3000, this.autorotate);
    },
    registerControls() {
      this.controls.registerMethod(
        'UpKey',
        new Marzipano.KeyControlMethod(
          38,
          'y',
          -this.controlVelocity,
          this.controlFriction
        ),
        true
      );
      this.controls.registerMethod(
        'DownKey',
        new Marzipano.KeyControlMethod(
          40,
          'y',
          this.controlVelocity,
          this.controlFriction
        ),
        true
      );
      this.controls.registerMethod(
        'LeftKey',
        new Marzipano.KeyControlMethod(
          37,
          'x',
          -this.controlVelocity,
          this.controlFriction
        ),
        true
      );
      this.controls.registerMethod(
        'RightKey',
        new Marzipano.KeyControlMethod(
          39,
          'x',
          this.controlVelocity,
          this.controlFriction
        ),
        true
      );
    },
    unregisterControls() {
      this.controls.unregisterMethod('UpKey');
      this.controls.unregisterMethod('DownKey');
      this.controls.unregisterMethod('LeftKey');
      this.controls.unregisterMethod('RightKey');
    },
  },
};
</script>

<style>
.panoContainer {
  overflow: hidden;
  width: '100%';
  height: 100vh;
  user-select: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  background: #222;
}

.panoContainer::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}
</style>
