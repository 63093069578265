<template>
  <div ref="elem">
    <Hotspot :design="mergedDesign" @click="handleClick"> </Hotspot>
    <portal to="renderer">
      <Modal v-model="active">
        <div
          v-if="form"
          class="w-11/12 sm:w-10/12 max-w-2xl h-screen py-10 flex justify-center items-center relative"
        >
          <iframe
            class="w-full h-full border-none"
            frameborder="0"
            src="https://redbull.jotform.com/form/210663657984064"
          ></iframe>
        </div>
        <div v-else class="w-11/12 sm:w-1/2 md:w-10/12 max-w-xl relative">
          <div class="w-full aspect-w-1 aspect-h-1 relative">
            <div class="absolute top-0 left-0 right-0 bototm-0">
              <iframe
                v-if="active"
                class="w-full h-full border-none overflow-hidden"
                frameborder="0"
                allowtransparency="true"
                allow="encrypted-media"
                :src="srcMap"
              ></iframe>
            </div>
          </div>
        </div>
      </Modal>
    </portal>
  </div>
</template>

<script>
import { merge } from 'lodash';
import Hotspot from './Hotspot';
import Modal from '../modal/Modal';

export default {
  name: 'IframeHotspot',
  props: {
    sceneData: { type: Object },
    view: { type: Object },
    scene: { type: Object },
    design: { type: Object, default: () => ({}) },
    coords: { type: Object },
    //
    src: { type: String },
  },
  components: { Hotspot, Modal },
  mounted() {
    this.scene.hotspotContainer().createHotspot(this.$refs.elem, this.coords, {
      perspective: {
        radius: 700,
      },
    });
    window.addEventListener('message', this.handleMessage);
  },
  data() {
    return {
      active: false,
      form: false,
    };
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleMessage);
  },
  computed: {
    srcMap() {
      return this.src.replace(
        '{VUE_APP_PUBLIC_PATH}',
        process.env.VUE_APP_PUBLIC_PATH || ''
      );
    },
    mergedDesign() {
      return merge({ icon: 'mdi-book-open-variant' }, this.design);
    },
  },
  methods: {
    handleMessage(evt) {
      if (evt.data === 'hs-success') {
        this.form = true;
      }
    },
    handleClick() {
      this.$store.dispatch('visit', { type: 'square', id: this.src });
      this.active = true;
    },
  },
};
</script>
