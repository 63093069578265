<template>
  <div ref="elem">
    <Hotspot :design="mergedDesign" @click="handleClick"> </Hotspot>
    <portal to="renderer">
      <!--<div v-if="active" v-scroll-lock="false"></div>-->
      <Modal v-model="active">
        <div v-if="active" class="w-11/12 sm:w-10/12 relative">
          <div class="relative w-full aspect-w-16 aspect-h-9 overflow-hidden">
            <div id="twitch-embed"></div>
          </div>
        </div>
      </Modal>
    </portal>
  </div>
</template>

<script>
import { merge, find } from 'lodash';
import Hotspot from './Hotspot';
import Modal from '../modal/Modal';
import Twitch from '@/assets/twitch';
import calendar from '@/assets/calendar';

export default {
  name: 'TwitchHotspot',
  head() {
    return {
      // script: [{ src: '//embed.twitch.tv/embed/v1.js', async: true }],
    };
  },
  props: {
    sceneData: { type: Object },
    sceneName: { type: String },
    view: { type: Object },
    scene: { type: Object },
    design: { type: Object, default: () => ({}) },
    coords: { type: Object },
    //
  },
  components: { Hotspot, Modal },
  mounted() {
    this.scene.hotspotContainer().createHotspot(this.$refs.elem, this.coords, {
      perspective: {
        radius: 700,
        //extraTransforms: 'rotateX(80deg)',
      },
    });
  },
  data() {
    return {
      active: false,
      calendar,
    };
  },
  computed: {
    mergedDesign() {
      return merge(
        {
          class: this.currentItem ? 'block animate-pulse' : 'hidden',
          icon: 'mdi-video',
          tooltip: this.$t('live') + ': ' + this.currentTitle,
        },
        this.design
      );
    },
    currentItem() {
      return find(this.calendar, (i) => {
        return (
          i.location === this.sceneName &&
          Date.now() >= new Date(i.startTime).getTime() &&
          Date.now() <= new Date(i.endTime).getTime()
        );
      });
    },
    currentChannel() {
      if (!this.currentItem) return null;
      return this.getLangProp(this.currentItem.channel);
    },
    currentTitle() {
      if (!this.currentItem) return null;
      return this.getLangProp(this.currentItem.title);
    },
  },
  methods: {
    handleClick() {
      this.active = true;
      this.$store.dispatch('visit', { type: 'twitch', id: this.channel });
      setImmediate(() => {
        new Twitch.Embed('twitch-embed', {
          layout: 'video',
          channel: this.currentChannel,
          width: '100%',
          height: '100%',
        });
      });
    },
    getLangProp(prop) {
      if (typeof prop === 'string') {
        return prop;
      }
      if (typeof prop === 'object') {
        return prop[this.$root.version];
      }
      return null;
    },
  },
};
</script>
