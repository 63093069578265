<template>
  <div>
    <div
      ref="panoContainer"
      class="panoContainer"
      @click.alt="rightClickHandler"
    />
    <div>
      <template v-if="visible">
        <component
          v-for="(hs, index) in hotspots"
          :is="types[hs.type]"
          :scene="hotspotContainer"
          :zoom="zoom"
          v-bind="hs"
          :key="`hs_${index}`"
        />
      </template>
    </div>
    <!-- <portal-target name="expo" multiple /> -->
  </div>
</template>

<script>
import Marzipano from 'marzipano';
import MapLinkHotspot from '@/components/hotspots/MapLinkHotspot';
import maplinks from '@/assets/maplinks';

let mapView, mapScene, mapViewer;

export default {
  name: 'MapRenderer',
  components: {
    MapLinkHotspot,
  },
  props: {},
  data() {
    return {
      hotspots: maplinks,
      types: {
        link: MapLinkHotspot,
      },
      imgUrlPrefix: (process.env.VUE_APP_PUBLIC_PATH || '') + '/tiles/harita',
      // imgUrlPrefix: '/tiles/harita',
      viewerOpts: {
        mouseViewMode: 'drag',
      },
      hotspotContainer: null,
      visible: false,
      zoom: 1,
    };
  },
  mounted() {
    // window.addEventListener('mousemove', onMouseMove);
    setTimeout(() => {
      this.initPano();
    }, 150);
  },
  beforeDestroy() {
    // window.removeEventListener('mousemove', onMouseMove);
    mapScene?.removeEventListener('viewChange', this.updateView);
    mapViewer?.destroy();
  },
  computed: {},
  methods: {
    rightClickHandler(e) {
      if (mapView) {
        const coords = mapView.screenToCoordinates({
          x: e.clientX,
          y: e.clientY,
        });
        console.log(coords);
        const string = JSON.stringify(coords, null, 2);
        navigator.clipboard.writeText(string);
      }
    },
    initPano() {
      mapViewer = new Marzipano.Viewer(
        this.$refs.panoContainer,
        this.viewerOpts
      );

      const source = Marzipano.ImageUrlSource.fromString(
        `${this.imgUrlPrefix}/{z}/{x}_{y}.jpeg`
      );

      const geometry = new Marzipano.FlatGeometry([
        {
          tileWidth: 512,
          tileHeight: 512,
          width: 512,
          height: 512,
          fallbackOnly: true,
        },
        { tileWidth: 512, tileHeight: 512, width: 1024, height: 1024 },
        { tileWidth: 512, tileHeight: 512, width: 2048, height: 2048 },
        { tileWidth: 512, tileHeight: 512, width: 4096, height: 4096 },
        { tileWidth: 512, tileHeight: 512, width: 8192, height: 8192 },
        { tileWidth: 512, tileHeight: 512, width: 16384, height: 16384 },
      ]);

      // Create view.
      // The letterbox view limiter allows the view to zoom out until the image is
      // fully visible, adding black bands around the image where necessary.
      const limiter = Marzipano.util.compose(
        Marzipano.FlatView.limit.zoom(
          0,
          Math.min(window.innerWidth / window.innerHeight, 1)
        ),
        Marzipano.FlatView.limit.resolution(16384),
        Marzipano.FlatView.limit.letterbox()
      );
      mapView = new Marzipano.FlatView(
        {
          y: 0.6,
          zoom: window.innerWidth < 675 ? 0.5 : 0.85,
          mediaAspectRatio: 16384 / 16384,
        },
        limiter
      );
      mapView.addEventListener('resize', () => {
        const newLimiter = Marzipano.util.compose(
          Marzipano.FlatView.limit.zoom(
            0,
            Math.min(window.innerWidth / window.innerHeight, 1)
          ),
          Marzipano.FlatView.limit.resolution(16384),
          Marzipano.FlatView.limit.letterbox()
        );
        mapView.setLimiter(newLimiter);
      });

      mapScene = mapViewer.createScene({
        source,
        geometry,
        view: mapView,
        pinFirstLevel: true,
      });

      this.hotspotContainer = mapScene.hotspotContainer();

      mapScene.addEventListener('viewChange', this.updateView);
      mapScene.switchTo();
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 150);
    },
    updateView() {
      this.visible = mapScene.visible();
      const newZoom = mapView.zoom();
      if (this.visible && this.zoom !== newZoom) {
        /*
       const mmc = mapView.screenToCoordinates(mouseCoords);
        const dx = mouseMapCoords.x - mmc.x;
        const dy = mouseMapCoords.y - mmc.y;

        const cx = mapView.x();
        const cy = mapView.y();

        console.log(dx, dy, cx, cy);
        mapView.setX(cx + dx / 2);
        mapView.setY(cy + dy / 2);
        // mapView.offsetX(dx / 2);
        // mapView.offsetY(dy / 2);
        */
        this.zoom = newZoom;
      }
    },
    offsetZoom(val) {
      mapView.offsetZoom(val);
    },
  },
};
</script>

<style>
.panoContainer {
  overflow: hidden;
  width: '100%';
  height: 100vh;
  user-select: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  background: #222;
}

.panoContainer::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}
</style>
