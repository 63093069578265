<template>
  <div ref="elem">
    <div class="hotspot">
      <!--
        <div
          class="popper mb-2 text-sm bg-gray-800 bg-opacity-80 rounded px-3 py-1 text-white"
        >
          Haydarpaşa
        </div>
        -->
      <button
        slot="reference"
        :style="mainStyle"
        @click="handleClick"
        class="relative flex w-7 h-7 sm:w-9 sm:h-9 md:w-11 md:h-11 justify-center content-center items-center rounded-full buttonGradient buttonTextShadow border-2 border-solid border-white text-white text-lg md:text-2xl font-black shadow-miniglow hover:bg-primary-active hover:shadow-glow transition-all focus:outline-none focus:ring-0"
      >
        <div
          class="absolute whitespace-nowrap top-0 left-1/2 -mt-4 md:-mt-8 text-xxs leading-tight md:text-sm bg-brand-red rounded px-1.5 md:px-3 md:py-0.5 text-white font-medium transform -translate-x-1/2"
        >
          {{ $t(`districts.${title}`) }}
        </div>
        <!--
        <div
          class="border-2 border-solid border-cyan-300 absolute top-0.5 -right-1 -bottom-1 left-0.5 shadow-miniglowcyan z-0 rounded-full opacity-70 animate-pulse"
        />
        -->
        <i class="iconShadow mdi mdi-map-marker" />
      </button>

      <!--
      <button
        :style="mainStyle"
        class="group border-solid border sm:border-2 md:border-3 lg:border-4 border-supplementary-magenta shadow-miniglow focus:outline-none hover:shadow-glow transition-all duration-1000 transform opacity-40 hover:opacity-100"
        @click="handleClick"
      >
        <div
          class="opacity-0 border-2 border-solid border-cyan-300 absolute top-0 left-0 right-0 bottom-0 shadow-miniglowcyan z-0 group-hover:opacity-100 duration-1000 transform hover:scale-125"
        />
      </button>
      -->
    </div>
  </div>
</template>

<script>
// relative w-14 h-14 flex justify-center content-center items-center buttonGradient text-white border-solid border-supplementary-magenta border-2 py-1 px-3 cursor-pointer shadow-miniglow hover:bg-primary-active hover:shadow-glow transition-all rounded-full transform
export default {
  name: 'MapLinkHotspot',
  props: {
    scene: { type: Object },
    coords: { type: Object },
    to: { type: String },
    href: { type: String },
    zoom: { type: Number, default: 1 },
    title: { type: String, default: '' },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.scene.createHotspot(this.$refs.elem, this.coords);
  },
  computed: {
    mainStyle() {
      return {
        // transform: `scale(${1 / this.zoom})`,
      };
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
      if (this.to) {
        this.$router.push(this.to);
      }
    },
  },
};
</script>

<style scoped>
.hotspot {
  transform: translate(-50%, -50%);
}
</style>
