import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
import axios from 'axios';
import md5 from 'md5';
import { intersection } from 'lodash';

const ls = new SecureLS({ isCompression: true });
const dataPath =
  localStorage.getItem('scenesPath') || process.env.VUE_APP_SCENES_PATH || '';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    visitorId: null,
    scenes: [],
    target: [],
    progress: [],
  },
  mutations: {
    SET_VISITORID(state, payload) {
      state.visitorId = payload;
    },
    SET_TARGET(state, payload) {
      state.target = payload;
    },
    SET_SCENES(state, payload) {
      state.scenes = payload;
    },
    ADD_PROGRESS(state, payload) {
      if (!state.progress.includes(payload)) {
        state.progress.push(payload);
      }
    },
  },
  actions: {
    async populateData({ commit }, payload) {
      const { data: scenes } = await axios.get(`${dataPath}/scenes/_list.json`);
      const { data: target } = await axios.get(
        payload === 'en'
          ? `${dataPath}/scenes/_progress_en.json`
          : `${dataPath}/scenes/_progress.json`
      );

      commit('SET_TARGET', target);
      commit('SET_SCENES', scenes);
    },
    async visit({ commit, state, getters }, { type, id }) {
      commit('ADD_PROGRESS', md5(`${type}:${id}`));
      this._vm.$gtag.event('visit', {
        event_category: type,
        event_label: id,
      });
      this._vm.$gtag.event('progress', {
        event_category: 'progress_update',
        label: state.visitorId,
        value: getters.progressValue,
      });
      console.log('visit:', type, id);
    },
  },
  getters: {
    isVisited(state) {
      return (id) => {
        return state.progress.includes(md5(id));
      };
    },
    progressValue(state) {
      const visited = intersection(state.target, state.progress);
      if (!visited.length) {
        return 0;
      }
      return visited.length / state.target.length;
    },
  },
  modules: {},
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
