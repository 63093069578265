<template>
  <div
    class="min-h-screen flex flex-col items-center justify-center bg-image px-5 pb-5 pt-16 sm:px-10 sm:pb-10 sm:pt-24"
  >
    <Fireflies />

    <div
      style="min-height: 75vh"
      class="relative w-full flex justify-center items-stretch border-2 border-solid border-white shadow-miniglow bg-image-2"
    >
      <div class="flex flex-col justify-between items-center space-y-16 py-8">
        <div class="h-18 sm:h-24 w-full" />
        <!--
        <transition
          mode="out-in"
          enter-active-class="transition-all transform duration-1000"
          leave-active-class="transition-all transform duration-1000"
          enter-class="opacity-0 translate-y-10"
          enter-to-class="opacity-100"
          leave-class="opacity-100"
          leave-to-class="opacity-0 -translate-y-10"
        >
          <div
            v-if="false"
            key="introstep0"
            class="flex flex-col space-y-8 2xl:space-y-12 justify-center items-center"
          >
            <img
              ref="meo3logo"
              src="../assets/logo.png"
              alt="meo3 logo"
              class="object-contain object-center max-h-32 sm:max-h-48 md:max-h-64 2xl:max-h-72"
            />
            <div
              class="px-10 text-2xl md:text-3xl lg:text-5xl 2xl:text-6xl font-black text-white text-center textDropShadow font-display"
              v-html="$t('intro.headline1')"
            ></div>
            <div class="flex items-center justify-center">
              <Button to="/map"
                >{{ $t('intro.button1') }}
                <i class="mdi mdi-arrow-right-thick ml-2 -mr-3"
              /></Button>
            </div>
          </div>
          -->
        <div key="introstep1" class="flex flex-col sm:flex-row px-10">
          <img
            ref="meo3logo"
            src="../assets/logo.png"
            alt="meo3 logo"
            class="object-contain object-center max-h-32 sm:max-h-48 md:max-h-64 2xl:max-h-72"
          />
          <div
            class="max-w-lg 2xl:max-w-xl space-y-4 md:space-y-10 flex flex-col items-center justify-center"
          >
            <h1
              class="text-2xl md:text-3xl lg:text-5xl 2xl:text-6xl text-white text-center textDropShadow font-display"
              style="line-height: 1.3"
              v-html="$t('intro.headline2')"
            ></h1>
            <p
              class="textDropShadow font-subhead text-center text-sm md:text-lg font-medium uppercase"
            >
              {{ $t('intro.subhead2') }}
            </p>
            <Button to="/map"
              >{{ $t('intro.button2') }}
              <i class="mdi mdi-arrow-right-thick ml-2 -mr-3"
            /></Button>
          </div>
        </div>

        <div class="h-18 md:h-24 w-full" />
        <div
          class="absolute bottom-0 pointer-events-none mx-auto flex justify-center pb-5 px-5 max-w-sm lg:max-w-md"
        >
          <img
            src="@/assets/gamelogos.png"
            class="w-full h-auto object-contain"
          />
        </div>
      </div>
    </div>
    <div class="flex justify-center p-5 max-w-sm lg:max-w-md">
      <img src="@/assets/sponsor.png" class="w-full h-auto object-contain" />
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button';
import Fireflies from '@/components/Fireflies';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'Home',
  layout: 'intro',
  components: { Button, Fireflies },
  data() {
    return {
      step: 0,
      timeline: null,
    };
  },
  watch: {},
  mounted() {
    setImmediate(() => {
      this.timeline = gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$refs.meo3logo,
            start: 'top 60',
            toggleActions: 'play none none reverse',
          },
        })
        .set(this.$refs.meo3logo, { autoAlpha: 1 })
        .to(this.$refs.meo3logo, { autoAlpha: 0 });
    });
  },
  methods: {},
};
</script>

<style scoped>
.bg-image {
  background: url('../assets/patern.jpg') repeat;
  box-shadow: inset 0px 0px 33px 0px rgba(0, 0, 0, 0.75);
}

.bg-image-2 {
  background: url('../assets/background.jpg') no-repeat center center;
  background-size: cover;
}
</style>
