<template>
  <div ref="elem">
    <Hotspot
      :design="mergedDesign"
      @click="handleClick"
      @mouseenter.native="hover = true"
      @mouseleave.native="hover = false"
    >
    </Hotspot>
  </div>
</template>

<script>
import { merge } from 'lodash';
import Hotspot from './Hotspot';
export default {
  name: 'SceneHotspot',
  props: {
    sceneData: { type: Object },
    view: { type: Object },
    scene: { type: Object },
    design: { type: Object, default: () => ({}) },
    coords: { type: Object },
    //
    to: { type: String },
  },
  components: { Hotspot },
  mounted() {
    this.scene.hotspotContainer().createHotspot(this.$refs.elem, this.coords, {
      perspective: {
        radius: 700,
      },
    });
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    mergedDesign() {
      return merge({ icon: 'mdi-arrow-up-thick' }, this.design);
    },
  },
  methods: {
    calculateDegree(degree) {
      return (degree / 180 || 0) * Math.PI;
    },
    handleClick() {
      const scY =
        this.calculateDegree(this.sceneData.viewOffset.yaw) - this.view.yaw();
      const scP =
        this.calculateDegree(this.sceneData.viewOffset.pitch) +
        this.view.pitch();
      this.$router.push(`/pano/${this.to}?y=${scY}&p=${scP}`);
      // console.log(this.scene.view.yaw(), this.scene.view.pitch());
    },
  },
};
</script>
