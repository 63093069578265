export default [
  {
    title: {
      tr: 'UNLOST: DÜNYA FİNALLERİ AÇILIŞI ve HEARTHSTONE YAYINI',
      en: 'Unlost: World Finals Opening & Hearthstone Showmatch',
    },
    startTime: '2021-03-17T15:45:00.000Z',
    endTime: '2021-03-17T18:15:00.000Z',
    location: 'Kapalicarsi_A03_Bedesten_3',
    channel: 'redbulltr',
  },
  {
    title: {
      tr: 'VURAL ÜZÜL: PUBG MOBILE GÖSTERİ MAÇI',
      en: 'Vural UZUL: PUBG Mobile Showmatch',
    },
    startTime: '2021-03-18T15:45:00.000Z',
    endTime: '2021-03-18T18:15:00.000Z',
    location: 'haydarpasa_05_gumrukKekran',
    channel: 'redbulltr',
  },
  {
    title: {
      tr: 'Futbolist vs. G2 Esports: Hearthstone Gösteri Maçı',
      en: 'Futbolist vs. G2 Esports: Showmatch',
    },
    startTime: '2021-03-19T15:45:00.000Z',
    endTime: '2021-03-19T18:15:00.000Z',
    location: 'Kapalicarsi_A03_Bedesten_3',
    channel: 'redbulltr',
  },
  {
    title: {
      tr: 'Hearthstone Dünya Finali',
      en: 'Hearthstone World Final',
    },
    startTime: '2021-03-20T16:45:00.000Z',
    endTime: '2021-03-20T21:15:00.000Z',
    location: 'Kapalicarsi_A03_Bedesten_3',
    channel: { tr: 'redbulltr', en: 'redbull' },
  },
  {
    title: 'Egoist Pati & Futbolist: 4 vs. 96',
    startTime: '2021-03-21T15:45:00.000Z',
    endTime: '2021-03-21T18:15:00.000Z',
    location: 'haydarpasa_05_gumrukKekran',
    channel: 'redbulltr',
  },
  {
    title: {
      tr: 'RED BULL M.E.O. PUBG MOBILE DOĞU DÜNYA FİNALİ',
      en: 'RED BULL M.E.O. PUBG Mobile East World Finals',
    },
    startTime: '2021-03-22T11:45:00.000Z',
    endTime: '2021-03-22T17:15:00.000Z',
    location: 'Pubg_WF_Sahne',
    channel: { tr: 'redbulltr', en: 'redbull' },
  },
  {
    title: {
      tr: 'Nisa Ölmez: PUBG Mobile 4 vs. 4',
      en: 'Nisa Olmez: PUBG Mobile 4 vs. 4',
    },
    startTime: '2021-03-25T18:15:00.000Z',
    endTime: '2021-03-25T20:45:00.000Z',
    location: 'haydarpasa_05_gumrukKekran',
    channel: 'redbulltr',
  },
  {
    title: {
      tr: 'Unlost: PUBG Mobile Gösteri Maçı',
      en: 'Unlost: PUBG Mobile Showmatch',
    },
    startTime: '2021-03-24T15:45:00.000Z',
    endTime: '2021-03-24T18:15:00.000Z',
    location: 'haydarpasa_05_gumrukKekran',
    channel: 'redbulltr',
  },
  {
    title: 'Post 42 & Futbolist: Soğuk Savaş',
    startTime: '2021-03-25T15:45:00.000Z',
    endTime: '2021-03-25T18:15:00.000Z',
    location: 'haydarpasa_05_gumrukKekran',
    channel: 'redbulltr',
  },
  {
    title: {
      tr: 'Thaldrin & Stratera: TFT Gösteri Maçı',
      en: 'Thaldrin & Stratera TFT Showmatch',
    },
    startTime: '2021-03-26T15:45:00.000Z',
    endTime: '2021-03-26T18:15:00.000Z',
    location: 'ortakoy_05',
    channel: 'redbulltr',
  },
  {
    title: {
      tr: 'RED BULL M.E.O. TFT DÜNYA FİNALİ',
      en: 'RED BULL M.E.O. TFT World Final',
    },
    startTime: '2021-03-27T16:45:00.000Z',
    endTime: '2021-03-27T21:15:00.000Z',
    location: 'ortakoy_05',
    channel: { tr: 'redbulltr', en: 'redbull' },
  },
  {
    title: {
      tr: 'PUBG Mobile Allstar Yayını',
      en: 'PUBG Mobile Allstar',
    },
    startTime: '2021-03-28T15:45:00.000Z',
    endTime: '2021-03-28T18:15:00.000Z',
    location: 'haydarpasa_05_gumrukKekran',
    channel: 'redbulltr',
  },
  {
    title: {
      tr: 'RED BULL M.E.O. PUBG MOBILE BATI DÜNYA FİNALİ',
      en: 'RED BULL M.E.O. PUBG Mobile West World Finals',
    },
    startTime: '2021-03-29T14:45:00.000Z',
    endTime: '2021-03-29T21:15:00.000Z',
    location: 'Pubg_WF_Sahne',
    channel: { tr: 'redbulltr', en: 'redbull' },
  },
];
