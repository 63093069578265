<template>
  <div
    v-show="value"
    :class="`fixed top-0 left-0 w-screen h-screen max-h-screen z-200 flex justify-center items-center overflow-y-auto ${
      background ? 'bg-gray-800 bg-opacity-70' : ''
    }`"
  >
    <slot />
    <button
      v-if="close"
      @click="$emit('input', false)"
      class="absolute right-5 top-5 w-10 h-10 bg-gray-800 text-gray-400 bg-opacity-50 rounded-full hover:bg-opacity-70 hover:text-gray-50 flex justify-center items-center focus:outline-none"
    >
      <i class="mdi mdi-close text-3xl text-white" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    value: { type: Boolean },
    background: { type: Boolean, default: true },
    close: { type: Boolean, default: true },
  },
};
</script>

<style></style>
