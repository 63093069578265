import Vue from 'vue';
import Router from 'vue-router';

// context items
import store from './store';
import i18n from './plugins/i18n';

// middlewares
import Pipeline from './middlewares/pipeline';
// import Fullscreen from './middlewares/fullscreen';

// PAGES
import Home from './pages/index';
import Map from './pages/map';
import PanoDesign from './pages/pano/_design';
import Markdown from './pages/md';

Vue.use(Router);

const globalMiddlewares = [];

export default (homeProps = { redirect: '/intro' }) => {
  const router = new Router({
    mode: 'hash',
    routes: [
      { path: '/', ...homeProps },
      { path: '/intro', component: Home },
      { path: '/map', component: Map },
      { path: '/md', component: Markdown },
      { path: '/pano', redirect: '/map' },
      { path: '/pano/:design', component: PanoDesign },
      { path: '*', redirect: '/' },
    ],
  });

  router.beforeEach((to, from, next) => {
    const middlewares = [...globalMiddlewares];
    if (to.meta?.middlewares?.length) {
      middlewares.push(...to.meta.middlewares);
    }

    if (!middlewares.length) {
      return next();
    }

    const context = {
      to,
      from,
      next,
      store,
      i18n,
      router,
    };

    return middlewares[0]({
      ...context,
      next: Pipeline(context, middlewares, 1),
    });
  });

  return router;
};
