<template>
  <div id="meo3app" class="font-display text-white border-solid">
    <router-view />
    <portal-target name="renderer" multiple />
    <SceneAnimations />
  </div>
</template>

<script>
import SceneAnimations from '@/components/SceneAnimations';
export default {
  name: 'StandaloneLayout',
  components: { SceneAnimations },
  data() {
    return {};
  },
  head() {
    return {
      // <meta name="viewport" content="target-densitydpi=device-dpi, width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, minimal-ui" />
      meta: [
        {
          name: 'viewport',
          content:
            'target-densitydpi=device-dpi, width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, minimal-ui',
        },
      ],
    };
  },
  created() {
    this.$root.version = 'tr';
    this.$root.embedded = false;
    this.$store.dispatch('populateData', this.$root.version);
  },
  beforeDestroy() {},
  methods: {},
};
</script>

<style></style>
