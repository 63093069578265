<template>
  <div
    class="text-gray-800 font-sans w-full max-w-sm bg-white rounded shadow-md flex flex-col divide-y text-sm"
  >
    <div class="flex flex-col space-y-3 p-3">
      <div>
        <label class="block">Coords: Yaw:</label>
        <input class="w-full" type="text" v-model="hotspot.coords.yaw" />
      </div>
      <div>
        <label class="block">Coords: Pitch:</label>
        <input class="w-full" type="text" v-model="hotspot.coords.pitch" />
      </div>
      <div>
        <label class="block">Type</label>
        <select class="w-full" v-model="hotspot.type">
          <option disabled value="">Select One</option>
          <option v-for="type in Object.keys(formFields)" :key="type">
            {{ type }}
          </option>
        </select>
      </div>
      <div v-for="ff in formFields[hotspot.type]" :key="ff.name">
        <label class="block">{{ ff.name }}</label>
        <select
          v-if="ff.type === 'select'"
          class="w-full"
          @input="setConfig(ff.name, $event)"
          :value="resolveConfig(ff.name)"
        >
          <option v-for="opt in ff.options" :key="opt">{{ opt }}</option>
        </select>
        <input
          v-else
          class="w-full"
          :type="ff.type"
          @input="setConfig(ff.name, $event)"
          :value="resolveConfig(ff.name)"
        />
      </div>
    </div>
    <div class="flex justify-end space-x-3 px-3 py-2">
      <button
        @click="$emit('cancel')"
        class="py-1.5 px-3 rounded hover:bg-gray-100"
      >
        Cancel
      </button>
      <button
        @click="$emit('save', hotspot)"
        class="py-1.5 px-3 rounded bg-gray-800 text-white hover:bg-gray-900"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { set, get } from 'lodash';

const langOpts = ['', 'tr', 'en', 'all'];

export default {
  components: {},
  name: 'HotspotSettings',
  props: {
    coords: { type: Object, default: () => ({ yaw: 0, pitch: 0 }) },
  },
  data() {
    return {
      drops: [
        '',
        'balat',
        'caddebostan',
        'galata',
        'haydarpasa',
        'kapalicarsi',
        'kopru',
        'ortakoy',
        'sultanahmet',
        'vapur',
      ],
      hotspot: {
        type: '',
      },
    };
  },
  computed: {
    formFields() {
      return {
        scene: [{ type: 'select', options: this.sceneList, name: 'to' }],
        sceneVertical: [
          { type: 'select', options: this.sceneList, name: 'to' },
        ],
        video: [
          { type: 'text', name: 'src' },
          { type: 'text', name: 'design.tooltip' },
          { type: 'select', options: langOpts, name: 'lang' },
        ],
        iframe: [
          { type: 'text', name: 'src' },
          { type: 'text', name: 'design.tooltip' },
          { type: 'select', options: langOpts, name: 'lang' },
        ],
        riddle: [
          { type: 'text', name: 'riddleId' },
          { type: 'text', name: 'design.tooltip' },
          { type: 'select', options: langOpts, name: 'lang' },
        ],
        drop: [{ type: 'select', options: this.drops, name: 'dropId' }],
        gallery: [
          { type: 'text', name: 'galleryId' },
          { type: 'text', name: 'design.tooltip' },
          { type: 'select', options: langOpts, name: 'lang' },
        ],
        twitch: [
          { type: 'text', name: 'channel' },
          { type: 'text', name: 'design.tooltip' },
          { type: 'select', options: langOpts, name: 'lang' },
        ],
        markdown: [
          { type: 'text', name: 'src' },
          { type: 'text', name: 'design.tooltip' },
          { type: 'select', options: langOpts, name: 'lang' },
        ],
        link: [
          { type: 'text', name: 'url' },
          { type: 'text', name: 'design.tooltip' },
          { type: 'select', options: langOpts, name: 'lang' },
        ],
      };
    },
    ...mapState({
      sceneList: (s) => s.scenes,
    }),
  },
  created() {
    this.hotspot.coords = this.coords;
  },
  methods: {
    resolveConfig(path) {
      get(this.hotspot, path);
    },
    setConfig(path, event) {
      set(this.hotspot, path, event.target.value);
    },
  },
};
</script>

<style></style>
