<template>
  <div class="relative" ref="pc" v-scroll-lock="true">
    <MapRenderer ref="mapref" />
    <Toolbox>
      <ToolButton
        v-if="$root.embedded && $root.version === 'en'"
        :tooltip="$t('toolbox.exit')"
        @click="handleExit"
      >
        <i class="mdi mdi-close-thick" />
      </ToolButton>
      <BackButton v-else to="back" />
      <MapNavigation />
      <Calendar />
      <Legend />
    </Toolbox>
    <div
      class="fixed top-12 sm:top-16 right-3 sm:right-5 flex flex-col items-end w-32 sm:w-64 space-y-4"
    >
      <ProgressBar />
      <div class="rounded-full bg-white text-gray-800 flex flex-col divide-y">
        <button
          class="px-2 py-1 sm:px-3 sm:py-2 focus:ring-0 focus:outline-none"
          @click="zoomIn"
        >
          <i class="mdi mdi-plus-thick" />
        </button>
        <button
          class="px-2 py-1 sm:px-3 sm:py-2 focus:ring-0 focus:outline-none"
          @click="zoomOut"
        >
          <i class="mdi mdi-minus-thick" />
        </button>
      </div>
    </div>
    <transition>
      <div
        v-if="showInfo"
        class="fixed left-0 right-0 bottom-5 top-auto px-5 sm:px-12 flex flex-row justify-center"
      >
        <div class="w-20 bg-brand-blue-2 my-3" />
        <div
          class="relative cursor-pointer flex-grow-0 flex flex-row justify-evenly items-center bg-white space-x-3 sm:space-x-6 px-2 sm:px-6 py-2 sm:py-4"
          @click="showInfo = false"
        >
          <div class="w-auto">
            <img
              ref="meo3logo"
              src="../assets/logo.png"
              alt="meo3 logo"
              class="object-contain object-center w-full h-auto max-h-14 sm:max-h-24 xl:max-h-32"
            />
          </div>
          <div
            class="text-brand-darkblue font-subhead text-xxs sm:text-xs md:text-sm lg:text-lg xl:text-xl 2xl:text-2xl"
          >
            {{ $t('map.popupbar') }}
            <button
              @click="showInfo = false"
              class="absolute right-0 top-0 -mr-5 -mt-5 w-10 h-10 bg-gray-800 text-gray-400 bg-opacity-50 rounded-full hover:bg-opacity-70 hover:text-gray-50 flex justify-center items-center focus:outline-none"
            >
              <i class="mdi mdi-close text-3xl text-white" />
            </button>
          </div>
        </div>
        <div class="w-20 bg-brand-red-2 my-3" />
      </div>
    </transition>
    <CalNotify v-if="!showInfo" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MapRenderer from '@/components/MapRenderer';
import BackButton from '@/components/BackButton';
import ToolButton from '@/components/ToolButton';
import Toolbox from '@/components/Toolbox';
import Calendar from '@/components/Calendar';
import Legend from '@/components/Legend';
import ProgressBar from '@/components/ProgressBar';
import MapNavigation from '@/components/MapNavigation';
import CalNotify from '@/components/CalNotify';

export default {
  name: 'Map',
  components: {
    MapRenderer,
    MapNavigation,
    BackButton,
    Toolbox,
    Calendar,
    Legend,
    ProgressBar,
    CalNotify,
    ToolButton,
  },
  data() {
    return {
      showInfo: false,
    };
  },
  created() {
    window.scroll(0, 0);
    if (!this.isVisited('map:')) {
      this.showInfo = true;
      this.$store.dispatch('visit', { type: 'map', id: '' });
    }
    // window.addEventListener('orientationchange', this.onOrientationChange);
  },
  beforeDestroy() {
    // window.removeEventListener('orientationchange', this.onOrientationChange);
  },
  computed: {
    ...mapGetters(['isVisited']),
  },
  methods: {
    zoomIn() {
      this.$refs.mapref.offsetZoom(-0.2);
    },
    zoomOut() {
      this.$refs.mapref.offsetZoom(+0.2);
    },
    handleExit() {
      this.$emit('exitExperience');
    },
  },
};
</script>

<style></style>
