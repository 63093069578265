export default [
  {
    type: 'link',
    coords: {
      x: 0.4328356459736824,
      y: 0.6762990802526474,
    },
    to: '/pano/sultanahmet_01',
    title: 'sultanahmet',
  },
  {
    type: 'link',
    coords: {
      x: 0.6871453523635864,
      y: 0.622910276055336,
    },
    to: '/pano/haydarpasa_01-2_Tren',
    title: 'haydarpasa',
  },
  {
    type: 'link',
    coords: {
      x: 0.6770707666873932,
      y: 0.7512801885604858,
    },
    to: '/pano/Feribot_02_onsahne',
    title: 'feribot',
  },
  {
    type: 'link',
    coords: {
      x: 0.35249003767967224,
      y: 0.6490028202533722,
    },
    to: '/pano/Kapalicarsi_01_HS_Game',
    title: 'kapalicarsi',
  },
  {
    type: 'link',
    coords: {
      x: 0.37458379566669464,
      y: 0.5038845725357533,
    },
    to: '/pano/Galata_01_CamekanSokak_r',
    title: 'galata',
  },
  {
    type: 'link',
    coords: {
      x: 0.16486127018928528,
      y: 0.5882685880362988,
    },
    to: '/pano/Balata_02_Ekran_360',
    title: 'balat',
  },
  {
    type: 'link',
    coords: {
      x: 0.681775227189064,
      y: 0.4078356847167015,
    },
    to: '/pano/Bogaz_koprusu_A01',
    title: 'kopru',
  },
  {
    type: 'link',
    coords: {
      x: 0.8197021782398224,
      y: 0.8526948392391205,
    },
    to: '/pano/Caddebostan_01_Platform',
    title: 'caddebostan',
  },
  {
    type: 'link',
    coords: {
      x: 0.5804844945669174,
      y: 0.43645911663770676,
    },
    to: '/pano/ortakoy_02',
    title: 'ortakoy',
  },
  {
    type: 'link',
    coords: {
      x: 0.5789710730314255,
      y: 0.8533263802528381,
    },
    to: '/pano/Pubg_WF_Sahne',
    title: 'pubgwf',
  },
];
