<template>
  <div>
    <Popper :options="{ placement: 'right-start' }">
      <div class="popper">
        <div
          class="ml-5 bg-gray-800 bg-opacity-80 rounded-md flex flex-col py-1 border-2 border-solid border-white shadow-miniglow"
        >
          <router-link
            v-for="item in links"
            :key="item.to"
            :to="item.to"
            class="px-3 py-1 hover:bg-brand-red text-white cursor-pointer text-xxs sm:text-xs whitespace-nowrap"
          >
            <i class="mdi mdi-map-marker mr-1.5" />
            {{ item.title }}
          </router-link>
        </div>
      </div>

      <button
        slot="reference"
        class="w-7 sm:w-10 h-7 sm:h-10 iconShadow flex justify-center content-center items-center rounded-full buttonGradient buttonTextShadow border-2 border-solid border-white text-white text-lg lg:text-xl font-black shadow-miniglow hover:shadow-glow transition-all transform focus:outline-none focus:ring-0"
        @click="onClick"
      >
        <i class="mdi mdi-map-outline" />
      </button>
    </Popper>
  </div>
</template>

<script>
import Popper from 'vue-popperjs';
import { sortBy } from 'lodash';
import maplinks from '@/assets/maplinks';

export default {
  name: 'Legend',
  components: { Popper },
  data() {
    return {
      links: sortBy(
        maplinks.map((i) => {
          return Object.assign({}, i, {
            title: this.$t(`districts.${i.title}`),
          });
        }),
        'title'
      ),
      active: false,
    };
  },
  methods: {
    onClick() {
      this.$router.push('/map');
    },
  },
};
</script>

<style></style>
